<script setup>
import { ref } from 'vue';
import draggable from 'vuedraggable'
import { usePublishedStore } from '@/stores';

const props = defineProps(['modelValue', 'disabled']);
const emit = defineEmits(['update:modelValue', 'onrelatedbrowse']);

const store = usePublishedStore();
// Internal state
const deleted = ref([]);
const showRemoteBrowser = ref(false);
const searchTerm = ref("");

function canMove() {
    return !props.disabled;
}

function remoteBrowser() {
    if(showRemoteBrowser) {
        //this.$emit('ondambrowse', ondambrowse);
        showRemoteBrowser.value = false;
    }
    showRemoteBrowser.value = true;
    emit('onrelatedbrowse', showRemoteBrowser);
}

function getRemoteTitle(remote) {
    return remote.title;
}

function remove(remote) {
    const modelValue = props.modelValue.filter(function(item) {
        return item.uid != remote.uid;
    });
    //deleted.add(remote.id);
    emit('update:modelValue', modelValue);
}

function onUpdateModelValue(event) {
    emit('update:modelValue', event);
}
</script>

<template>
    <div class="card">
		<div class="card-body">
			<div v-if="!disabled" class="btn btn-default btn-sm mb-3">
				<i class="fas fa-folder-open" @click="remoteBrowser" title="Sfoglia dall'archivio news"></i>
			</div>
			<draggable 
				:move="canMove"
				:modelValue="modelValue"
				@update:modelValue="onUpdateModelValue" 
				group="related-remotes"
				item-key="id" class="scrollable-box">
				<template #item="{ element }">
					<div :data-id="element.sorting" class="remote-container">
						<div style="width:calc(100% - 1.2rem);">{{ getRemoteTitle(element) }}</div>
                        <button v-if="!disabled" @click="remove(element)" class="fa fa-trash remove-btn" type="button"></button>
					</div>
				</template>
			</draggable>
		</div>
		<!-- div v-for="(file, index) in store.remoteDetailRelated.deleted_media" :key="index" class="m-top">
			<input type="text" name="deleted_media[]" :value="file.name" hidden>
		</div -->
	</div>
</template>

<style>
.remote-container {
    position: relative;
	width: 100%;
    padding: 10px;
	margin-bottom: 0.5rem !important;
	margin-right: 0.3rem !important;
	background: #EEE;
    cursor: move !important;
}
.remove-btn {
    color: #444;
	border: none;
	padding: 0;
    margin: 0;
	cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
	z-index:1;
}
.times-icon{
    font-size: 2rem !important;
    padding: 0px !important;
    margin:0px !important;
}
</style>