import { createI18n } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  return {
    "it": {
        "wrong-credentials": "Username o password errati",
        "select-almost-one-newspapaper": "Devi selezionare almeno un giornale",
        "select-almost-one-category": "Devi selezionare almeno una categoria",
        "session-expired": "Sessione scaduta.",
        "wrong-linked-news": "Errore sulle notizie Linkate. Verificare che sia selezionato un giornale Principale.",
    }
  }
}

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: "it",
  fallbackLocale: "it",
  messages: loadLocaleMessages(),
});