<script setup>
import { useFilemountStore, useBackendStore } from '@/stores';
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useLoading } from '@/loader';
import { useNotification } from "@kyvg/vue3-notification";
import FilemountVue from './Filemount.vue';
import { nextTick, ref, watch, computed, onMounted } from 'vue';
import TableLite from '../table-lite/src/components/TableLite.vue';
import { format, parseJSON } from 'date-fns';
import { useAuthStore, useNewsPaperStore } from '../../stores';

import { createConfirmDialog } from 'vuejs-confirm-dialog'
import ConfirmationModal from '@/components/confirmation-modal/src/ConfirmationModal.vue';

const renameConfirmDialog = createConfirmDialog(ConfirmationModal, {title: 'Conferma azione', message: 'Vuoi veramente rinominare il file?'});
const deleteConfirmDialog = createConfirmDialog(ConfirmationModal, {title: 'Conferma azione', message: 'Vuoi veramente eliminare il file?'});


const newspaperStore = useNewsPaperStore();
const filemountStore = useFilemountStore();
const backendStore = useBackendStore();
const authStore = useAuthStore();
const { notify } = useNotification();

const props = defineProps({
    "show": {
        "type": Boolean,
        "default": false
    },
    "store": {
        "type": Object,
        "default": {}
    },
    /**
     * Available "context"
     *  'upload': click on "Media" navbar link
     *  'attach': click on "browse folder" from Images on Edit News
     *  'file': click on "browse folder" from Attachments on Edit News
     * 'inline': click on "browse folder" from RTE toolbar
     */
    "context": {
        "type": String,
        "default": "attach"
    }
});

const emit = defineEmits(['closed', 'hidebsmodal', 'addimage']);

/**
 * Template Refs
 */

const imageTable = ref(null);
const dambrowser = ref(null);
const fileupload = ref(null);
const confirmDeleteFile = ref(null);

const imageList = ref(null);
let currentFilemountId = null;
const isLoading = ref(false);
const pagination = ref({
    currentPage: 1,
    totalPages: 0
});
const folderName = ref('');
const searchTerm = ref('');
const damLoader = useLoading();

const damLoading = ref(false);

const tableCols = [
    { label: "", width: "20%", field: "uid", isKey: true },
    { label: "Nome", width: "50%", field: "file_name", sortable: true },
    { label: "Data", width: "30%", field: "tstamp", sortable: true },
    { label: "", width: "10%", field: "actions" }
];

const dateFormat = "dd/MM/yyyy HH:mm";
const selectedRows = ref([]);

const pageSize = computed({
  get() {
    return authStore.user.preferences.lastPreferences.images.imagePageSize;
  },
  set(value) {
    console.log("Changed pageSize", value);
    if(authStore.user.preferences.lastPreferences.images.imagePageSize != value) {
      authStore.user.preferences.lastPreferences.images.imagePageSize = value;
      authStore.updatePreferences(authStore.user);
      //searchClick();
    }
  }
});

const filemountLastPath = computed({
    get() {
        return authStore.user.preferences.lastPreferences.images.selectedArchive;
    },
    set(value) {
        if(authStore.user.preferences.lastPreferences.images.selectedArchive != value) {
            authStore.user.preferences.lastPreferences.images.selectedArchive = value;
            authStore.updatePreferences(authStore.user);
        }
    }
});


const renamingFile = ref({
    'filemount_id': null,
    'file_id': null,
    'new_filename': null
});

const renameFile = (file) => {
    closeAllFileEdit();
    file.edit = true;
    renamingFile.value.new_filename = file.file_name;
}

const closeFileEdit = (file) => {
    file.edit = false;
}

const closeAllFileEdit = () => {
    imageList.value.forEach( (file) => {
        file.edit = false;
    });
}

const saveRenamedFile = async (file) => {
    const { data, isCanceled } = await renameConfirmDialog.reveal();
    if(isCanceled) {
        return;
    }
    damLoader.show({container: dambrowser.value });
    filemountStore.renameFile(currentFilemountId, file.uid, renamingFile.value.new_filename)
        .then( (response) => {
            notify({
                text: 'File rinominato!',
            });
            searchClick();
        })
        .catch( (reason) => {
            if(reason.message.includes("existed already")) {
                notify({
                text: "Impossibile rinominare il file. Un file con lo stesso nome è già presente.",
                type: 'error'
            });
            } else {
                notify({
                text: reason.message,
                type: 'error'
            });
            }
        })
        .finally( () => {
            file.edit = false;
            damLoader.hide();
        });
}

let deletingFile = {
  'filemount_id': null,
  'file_id': null
};

const showConfirmDeleteFile = async (file) => {
    deletingFile = {
        'filemount_id': currentFilemountId,
        'file_id': file.uid
  };
  //const response = confirm("Vuoi veramente eliminare il file?");
  const { data, isCanceled } = await deleteConfirmDialog.reveal();
  if(isCanceled) {
    cancelDeleteFile();
  } else {
    doDeleteFile();
  }
}

const cancelDeleteFile = () => {
    deletingFile = {
        'filemount_id': null,
        'file_id': null
    };
};

const doDeleteFile = () => {
    damLoader.show({container: dambrowser.value });
    filemountStore.deleteFile(deletingFile.filemount_id, deletingFile.file_id)
        .then( (response) => {
            notify({
                text: 'File eliminato!',
            });
            searchClick();
        })
        .catch( (reason) => {
            notify({
                text: reason,
                type: 'error'
            });
        })
        .finally( () => {
            deletingFile = {
                'filemount_id': null,
                'file_id': null
            };
            damLoader.hide();
        });
}

const toggleBackendStatus = (backend) => {
    backend.opened = !backend.opened;
};

const getNewspaperFilemount = (newspaper_id) => {
    const newspaper = newspaperStore.getNewspaperById(newspaper_id);
    const filemount = filemountStore.getFilemountById(newspaper['backendId'] + '-' + newspaper['file_mountpoints']);
    return filemount;
};

const updateCheckedRows = (rowsKeys) => {
    selectedRows.value = rowsKeys;
};

const onDamLoading = (status) => {
    if(status) {
        damLoading.value = true;
    } else {
        damLoading.value = false;
    }
};

const doSearch = (offset, limit, order, sort) => {
    searchImages(false, offset, limit, order, sort);
};

const searchClick = () => {
    if(!filemountLastPath.value) {
        notify({
            text: 'Selezionare prima una cartella!',
            type: 'warn'
        });
        return;
    }
    if(imageTable.value.setting.page == 1) {
        doSearch(0, pageSize.value, imageTable.value.setting.order, imageTable.value.setting.sort);
    } else {
        imageTable.value.setting.page = 1;
    }
}

const getFilemount = async () => {
    damLoader.show({container: dambrowser.value });
    let waiters = [];
    await filemountStore.fetchFilemount(authStore.user.preferences.hideArchiveTree);

    if(authStore.user.preferences?.newspapers.length == 0) {
        for (const [key, backend] of Object.entries(filemountStore.filemount)) {
            backend.opened = true;
        }
    }
    damLoader.hide();
};

const getFileThumb = (image) => {
    const parts = image.file_name.split('.');
    let ext = '';
    if(parts.length > 1) {
        ext = parts[parts.length - 1].toLowerCase();
    }
    if(Array('png', 'jpg', 'jpeg', 'bmp', 'tiff', 'gif', 'webp', 'jfif').includes(ext)) {
        let parts = currentFilemountId.split("-")
        for( let [filemount_name, filemount] of Object.entries(filemountStore.filemount[parts[0]])) {
            if(currentFilemountId == filemount.id) {
                return `${filemount.baseUrl}/${image.file_path}${image.file_name}`;
            }
        }
    }
    return new URL('../../assets/file_generic.svg', import.meta.url).href;
};

const onClickThumb = (event) => {
    $(event.currentTarget).ekkoLightbox({
        
        alwaysShowClose: true,
        /*
        backdrop: false,
        */
        onHidden: () => {
            $(document.body).addClass("modal-open");
        }
    });
}

const formatDateTime = (inputDate) => {
    let date;
    try {
        date = format(parseJSON(inputDate), dateFormat);
    } catch(err) {
        console.warn("Error formatting date", err, inputDate);
        date = '';
    }
    return date;
};

const doUpload = () => {
    if(fileupload.value.files.length == 0) {
        notify({
            text: 'Selezionare prima un file!',
            type: 'warn'
        });
        return;
    }

    if(!filemountLastPath.value) {
        notify({
            text: 'Selezionare prima una cartella!',
            type: 'warn'
        });
        return;
    }
    damLoading.value = true;
    
    let waiters = [];
    for(var i=0; i < fileupload.value.files.length; i++){
        let formData = new FormData
        formData.set('file', fileupload.value.files[i])
        
        waiters.push(filemountStore.uploadFile(filemountLastPath.value, formData))
    }
    Promise.all(waiters).then( val => {
        if(filemountStore.uploadErrors.length > 0) {
            notify({
                text: filemountStore.uploadErrors,
                type: 'error'
            });
        } else {
            //fileupload.value.value = '';
            searchImages();
        }
        fileupload.value.value = null;
        fileupload.value.dispatchEvent(new Event('change'));
        damLoading.value = false;
    });
};

const newFolder = () => {
    if(filemountLastPath.value) {
        damLoading.value = true;
        filemountStore.createFolder(filemountLastPath.value, folderName.value).then( resp => {
            folderName.value = '';
        }).catch((reason) => {
            notify({
                text: 'Errore del server. Codice: ' + reason,
                type: 'error'
            });
        }).finally(() => {
            damLoading.value = false;
        });
    } else {
        notify({
            text: 'Selezionare prima una cartella!',
            type: 'warn'
        });
    }
};

const closeBrowserModal = (e) => {
    emit('hidebsmodal');
};

const addSelected = () => {
    // Find selected images
    selectedRows.value.forEach((image_uid, index) => {
        addImage(imageList.value.find(item => item.uid == image_uid));
    });
    closeBrowserModal(null);
};

const addImage = (image) => {
    let storeImage = JSON.parse(JSON.stringify(image));
    storeImage.type = "remote";
    //storeImage.url = `${filemountStore.filemount[this.currentFilemountId].baseUrl}/${image.file_path}${image.file_name}`;
    storeImage.url = `${image.file_path}${image.file_name}`;
    let parts = currentFilemountId.split("-")
    for( let [filemount_name, filemount] of Object.entries(filemountStore.filemount[parts[0]])) {
        if(currentFilemountId == filemount.id) {
            storeImage.remote_id = filemount.backendId + "-" + storeImage.uid;
            storeImage.publicUrl = `${filemount.baseUrl}`;
        }
    }

    if(props.context == "attach") {
        if(!props.store.draftDetailImages.images.find((el) => {
            return el.remote_id === storeImage.remote_id;
        })) {
            props.store.draftDetailImages.images.push(storeImage);
        }
    } else if(props.context == "file") {
        if(!props.store.draftDetailFiles.files.find((el) => {
            return el.remote_id === storeImage.remote_id;
        })) {
            props.store.draftDetailFiles.files.push(storeImage);
        }
    } else {
        emit('addimage', storeImage);
        emit('closing');
    }
};

const isSelected = (id) => {
    return id == filemountLastPath.value;
};

const folderSelect = async (path) => {
    // From CategoryTree
    filemountLastPath.value = path;
    pagination.value.currentPage = 1;
    // List image only when click on search lens
    //this.searchClick();
    imageList.value = null;
};

const folderIsSelected = async (backend) => {
    backend.opened = true;
}

const searchImages = (loader = false, offset, limit, order, sort) => {
    return new Promise((resolve, reject) => {
        if(filemountLastPath.value) {
            imageList.value = null;
            if(!loader) {
                damLoading.value = true;
            }
            if (limit != pageSize.value) {
                pageSize.value = limit;
                //updateStatusInStore();
            }
            filemountStore.searchImages(filemountLastPath.value, searchTerm.value, offset, limit, order, sort).then( response => {
                imageList.value = response['images'];
                pagination.value.totalPages = response['totalPages'];
                pagination.value.currentPage = response['currentPage'];
                currentFilemountId = response['filemount_id'];
                pagination.value.totalResult = response['totalResult'];
                resolve();
            }).catch((reason) => {
                reject("Errore nel caricamento delle immagini");
            }).finally(() => {
                if(!loader) {
                    damLoading.value = false;
                }
            });
        } else {
            reject("Seleziona prima una cartella")
        }
    });
};

watch(damLoading, (value) => {
    if(value) {
        damLoader.show({container: dambrowser.value });
    } else {
        damLoader.hide();
    }
});

watch(() => props.show, (value) => {
    if (value) {
        setTimeout(() => {
            nextTick(() => {
                getFilemount();
                bsCustomFileInput.init();
            });
        },100);
    }
    else {
        emit('closed');
    }
});

const isOpened = (backend, backend_id) => {
    let traversed = false;
    if(filemountLastPath.value) {
        traversed = filemountLastPath.value.startsWith(backend_id + "-")
    }
    return backend.opened || traversed;
}

const preferredNewspapers = computed(() => {
    let newspapers = [];
    if(authStore.user?.preferences?.newspapers?.length > 0 && newspaperStore.newspaper && Object.keys(newspaperStore.newspaper).length > 0) {
        const filtered = authStore.user.preferences.newspapers.filter( (newspaper_id) => {
            let parts = newspaper_id.split('-');
            if(parts[0] in newspaperStore.newspaper) {
                for(const [name, newspaper] of Object.entries(newspaperStore.newspaper[parts[0]])) {
                    if(newspaper.uid == parts[1]) {
                        return true;
                    }
                }
            }
            return false;
        });
        newspapers = filtered;
    }
    return newspapers;
});

</script>

<template>
    <div v-if="show" ref="dambrowser" class="container-fluid p-0">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3">
                <div class="form-group">
                    <label for="file_upload">File upload</label>
                    <div class="input-group">
                        <div class="custom-file">
                            <input class="custom-file-input" id="file_upload" ref="fileupload" type="file" accept="*/*"
                                multiple>
                            <label class="custom-file-label" for="file_upload">Seleziona file</label>
                        </div>
                        <div class="input-group-append">

                            <span class="input-group-text" @click="doUpload">Upload</span>
                        </div>
                    </div>
                </div>

                <div id="filemounts">
                    <template v-if="preferredNewspapers.length > 0">
                        <strong>Preferiti</strong>
                        <ul class="filemount-list pref-filemount-list">
                            <template v-for="newspaper_id in preferredNewspapers">
                                <FilemountVue :filemount="getNewspaperFilemount(newspaper_id)"
                                    @folderselect="folderSelect" @opening="(status) => onDamLoading(status)" />
                            </template>
                        </ul>
                    </template>
                    <ul class="backend-list">
                        <li class="backend-item" v-for="(backend, backend_id) in filemountStore.filemount">
                            <i v-if="Object.keys(backend).length > 0"
                                :class="(isOpened(backend, backend_id) ? 'far fa-folder-open mr-1' : 'far fa-folder-closed mr-1')"
                                @click="toggleBackendStatus(backend)"></i>
                            <strong>{{ backendStore.getBackendName(backend_id) }}</strong>
                            <ul v-show="isOpened(backend, backend_id)" class="filemount-list">
                                <template v-for="(filemount, index) in backend">
                                    <FilemountVue v-if="typeof filemount == 'object'" :filemount="filemount"
                                        @folderselect="folderSelect" @opening="(status) => onDamLoading(status)" @folderIsSelected="folderIsSelected(backend)" />
                                </template>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div v-if="!authStore.user.preferences.hideArchiveTree" class="form-group">
                    <label for="new_folder">Nuova cartella</label>
                    <div class="input-group mb-3">
                        <input type="text" id="new_folder" class="form-control" v-model="folderName">
                        <div class="input-group-append">
                            <button class="btn btn-primary" @click.prevent="newFolder()">Crea</button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-8 col-lg-9">
                <div class="form-group">
                    <label for="search">Cerca</label>
                    <div class="input-group mb-3">
                        <input type="search" v-model="searchTerm" id="search" class="form-control"
                            @search="searchClick()">
                        <div class="input-group-append">
                            <button class="btn btn-primary" @click.prevent="searchClick()"><i
                                    class="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
                <div class="wrapper">
                    <button v-if="(context !== 'none' && context !== 'upload') && (imageList != null && imageList.length > 0)"  class="mb-2 btn btn-success"
                        @click.prevent="addSelected">+ Aggiungi selezionati</button>
                    <table-lite ref="imageTable" :has-checkbox="(context !== 'none' && context !== 'upload')"
                        :is-slot-mode="true" :rows="imageList" checkedReturnType="key" :page-size="pageSize"
                        @return-checked-rows="updateCheckedRows" :columns="tableCols" :total="pagination.totalResult"
                        @do-search="doSearch" :sortable="{order: 'tstamp', sort: 'desc'}">
                        <template v-slot:uid="data">
                            <div class="image-item" :data-txdam-uid="data.value.uid">
                                <a :href="getFileThumb(data.value)" data-toggle="lightbox" :data-title="data.value.file_name" @click.prevent="onClickThumb">
                                    <img :src="getFileThumb(data.value)" />
                                </a>
                            </div>
                        </template>
                        <template v-slot:file_name="data">
                            <div v-if="data.value.edit == true" class="filename-item">
                                <input class="form-control" type="text" v-model="renamingFile.new_filename">
                            </div>
                            <div v-else class="filename-item">
                                {{ data.value.file_name }}
                            </div>
                        </template>
                        <template v-slot:tstamp="data">
                            <div class="filename-item">
                                {{ formatDateTime(data.value.tstamp) }}
                            </div>
                        </template>
                        <template v-slot:actions="data">
                            <div class="text-center">
                                <div class="btn-group">
                                    <i v-if="data.value.edit == true" class="fa fa-save mr-1" @click="saveRenamedFile(data.value)"></i>
                                    <i v-else class="fa fa-edit mr-1" @click="renameFile(data.value)"></i>
                                    <i v-if="data.value.edit == true" class="fa fa-close mr-1" @click="closeFileEdit(data.value)"></i>
                                    <i v-else class="fa fa-trash mr-1" @click.prevent="showConfirmDeleteFile(data.value)"></i>
                                </div>
                            </div>
                        </template>
                    </table-lite>
                    <button v-if="(context !== 'none' && context !== 'upload') && (imageList != null && imageList.length > 0)" class="mb-2 btn btn-success"
                        @click.prevent="addSelected">+ Aggiungi selezionati</button>
                </div>
            </div>
        </div>
    </div>

</template>

<style>
#filemounts {
  max-height: 75vh;
  overflow-y: scroll;
}

li.filemount-item,
li.backend-item {
    list-style-type: none;
}

ul.filemount-list,
ul.backend-list,
ul.folders {
  padding-inline-start: 18px;
}
#filemounts > ul.filemount-list,
#filemounts > ul.backend-list,
#filemounts > ul.folders {
  padding-inline-start: 0px;
}

ul.folders {
  font-size: 14px;
}

.filemount-item > span.filemount,
.folder-item > span.folder {
  cursor:pointer;
}
.folder-item.selected > span.folder,
.filemount-item.selected > span.filemount {
  font-weight:bold;
}

.image-item {
    text-align: center;
}

.image-item img {
    min-width: 100px;
    max-width: 100px;
    max-height: 100px;
}

.modal-content .wrapper {
    min-height: 400px;
}

.Page {
    width:auto!important;
}

.times-icon {
    background: #fd7e14;
    color: #FFFFFF;
}

.custom-file-label::after {
    display: none;
}
</style>
