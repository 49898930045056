import { defineStore } from 'pinia';

import { useAuthStore } from '@/stores';
import { fetchWrapper } from '../_helpers/fetch-wrapper';

const baseUrl = `${import.meta.env.VITE_API_URL}/admin/users`;

const defaultUIStatus = {
    news: {
        tab: 'draft-news',
        quickfilter: 'all',
        language: 0,
        pageSize: 25,
    }
}

function getUIStatus() {
    const settings = localStorage.getItem('ui-status');
    return settings ? JSON.parse(settings) : defaultUIStatus;
}

export const useUsersStore = defineStore({
    id: 'users',
    state: () => ({
        users: {},
        user: {},
        isLoading: '',
        uiStatus: getUIStatus(),
    }),
    actions: {
        setUIStatus() {
            localStorage.setItem('ui-status', JSON.stringify(this.uiStatus));
        },
        async register(user) {
            return await fetchWrapper.post(`${baseUrl}`, user);
        },
        async getAll() {
            try {
                let resp = await fetchWrapper.get(baseUrl);  
                this.users = resp.users; 
            } catch (error) {
                this.users = { error };
            }
        },
        initUser() {
            this.user = {
                preferences: {
                    hideArchiveTree: false,
                    newspapers: [],
                    authors: {},
                }
            };
        },
        async getById(id) {
            return new Promise( (resolve, reject) => {
                fetchWrapper.get(`${baseUrl}/${id}`).then( (response) => {
                    this.user = response.user;
                    resolve(response.user);
                }).catch( (reason) => {
                    this.user = { error };
                    reject(reason);
                });
            });
        },
        async update(id, params) {
            await fetchWrapper.put(`${baseUrl}/${id}`, params);

            // update stored user if the logged in user updated their own record
            const authStore = useAuthStore();
            if (id === authStore.user.id) {
                // update local storage
                const user = { ...authStore.user, ...params };
                localStorage.setItem('user', JSON.stringify(user));

                // update auth user in pinia state
                authStore.user = user;
            }
        },
        async changePassword(id, new_password) {
            return new Promise((resolve, reject) => {
                fetchWrapper.put(`${baseUrl}/${id}`, {password: new_password}).then((value) => resolve())
            });
            
        },
        async delete(id) {
            const authStore = useAuthStore();
            // Cannot self delete
            if (id === authStore.user.id) {
                alert("Cannot self delete!");
                return;
            }
            // add isDeleting prop to user being deleted
           // this.users.find(x => x.id === id).isDeleting = true;

            await fetchWrapper.delete(`${baseUrl}/${id}`);

            // remove user from list after deleted
            this.users = this.users.filter(x => x.id !== id);
        }
    }
});