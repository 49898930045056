import { defineStore } from 'pinia';
import { fetchWrapper } from '../_helpers/fetch-wrapper';


const baseUrl = `${import.meta.env.VITE_API_URL}`;

export const useBackendStore = defineStore({
    id: 'backend',
    state: () => ({
        // initialize state from local storage
        backend: JSON.parse(localStorage.getItem('backend')),
        returnUrl: null
    }),
    actions: {
        async getBackend() {
            const backend = await fetchWrapper.get(`${baseUrl}/journal/newspaper/backends`);
            this.backend = backend.data;
            localStorage.setItem('backend', JSON.stringify(backend.data));
        },
         getBackendName(backend_id) {
            if(!this.backend) {
                this.getBackend().then(data => {
                    if(backend_id in this.backend) {
                        return this.backend[backend_id].name;
                    }
                })
            } else {
                if(backend_id in this.backend) {
                    return this.backend[backend_id].name;
                }
            }
            return '<Backend >' + backend_id;
          },
    }
});