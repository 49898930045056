<script setup>
import { provide } from 'vue';
import CategoryOption from './CategoryOption.vue';

const model = defineModel()

const props = defineProps({
    categories: {
        type: Array,
        required: true
    }
});

const emit = defineEmits(['update:modelValue']);

const onOptionChange = (event) => {
    model.value = event.target.value;
    emit('update:modelValue', event.target.value);
}

provide('selectedCategory', model);


</script>

<template>
<select @change="onOptionChange">
    <option value="0">Tutte le categorie</option>
    <CategoryOption v-for="category in categories" :category="category" :key="category.uid" :level="0"></CategoryOption>
</select>
</template>
