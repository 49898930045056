<template>
    <nav class="mt-2">
      <!--<ul class="nav nav-pills nav-sidebar flex-column nav-compact " data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item menu-open">
          <a href="#" class="nav-link active" >
              <i class="nav-icon fas fa-book"></i>
              <p>
                Gestione utenti
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link to="/admin/users" class="nav-link" :class="getMenuItemOpenClass('/admin/users')">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Utenti</p>
                </router-link >
              </li>
              <li class="nav-item">
                <router-link to="/admin/notifications" class="nav-link" :class="getMenuItemOpenClass('/admin/notifications')">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Notifiche</p>
                </router-link>
              </li>
            </ul>
        </li>
      </ul>-->
      
     
                <ul class="nav nav-pills nav-sidebar flex-column nav-compact " data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item menu-open">
                        <router-link to="/admin/users" class="nav-link" :class="getMenuItemOpenClass('/admin/users')">
                            <i class="nav-icon fas fa-users"></i>
                            <p>Utenti</p>
                        </router-link>
                        <router-link to="/admin/notifications" class="nav-link" :class="getMenuItemOpenClass('/admin/notifications')">
                            <i class="nav-icon fas fa-comments"></i>
                            <p>Notifiche</p>
                        </router-link>
                    </li>
                </ul>
            
        
    </nav>
</template>

<script setup>
import { reactive } from "vue";
import { useRouter } from 'vue-router';

const activeRoute = reactive({
    name: ''
});
const router = useRouter()

function getMenuItemOpenClass(item) {
    const className = router.currentRoute.value.path.startsWith(item) ? ' active bg-primary' : '';
    return className;
}

</script>