<script setup>
import { ref } from 'vue';
import { fetchWrapper } from '@/_helpers/fetch-wrapper';
import { format, formatDistance, formatRelative, subDays, parseISO } from 'date-fns'
import { useLoading } from '@/loader';
import { useRouter } from 'vue-router';
import TableLite from '@/components/table-lite';

const baseUrl = `${import.meta.env.VITE_API_URL}/admin/usernotifications`;

const loader = useLoading();
const router = useRouter();

const notifications = ref({
    totalRows: 0,
    rows: [],
});

// Generic configurations
const notificationTypes = [
    {
        id: 'generic',
        label: 'Generale'
    }, {
        id: 'releasenote',
        label: 'Release Note'
    }
];

const notificationTableCols = [
    { label: "Titolo", field: "title" },
    { label: "Tipo", width: "20%", field: "type", display: function(row) { return notificationTypes.find( (element) => element.id == row.type)?.label; } },
    { label: "Data inizio", width: "15%", field: "from", display: function(row) { return format(parseISO(row.from), 'dd/MM/yyyy HH:mm'); } },
    { label: "Data fine", width: "15%", field: "to", display: function(row) { try { return format(parseISO(row.to), 'dd/MM/yyyy HH:mm'); } catch(e) { return ''; } }},
    { label: "", width: "7%", field: "actions" },
];


function doSearch(offset, limit, order, sort) {
    console.log("doSearch", {
        offset: offset,
        limit: limit,
        order: order,
        sort: sort
    });

    const searchParams = new URLSearchParams({
        offset: offset,
        limit: limit,
        order: order,
        sort: sort
    });

    loader.show();
    fetchWrapper.get(baseUrl + "?" + searchParams.toString()).then( (result) => {
        notifications.value.rows = result.data.data;
        notifications.value.totalRows = result.data.total;
        
    }).finally( () => {
        loader.hide();
    });
}

function rowClicked(row) {
    router.push({ path: router.currentRoute.value.fullPath + '/' + row.id});
}

function newNotification() {
    router.push({ path: router.currentRoute.value.fullPath + '/create'});
}

doSearch(0, 10, 'from', 'desc');

</script>

<template>
<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
        <div class="col-sm-6">
            <h1>Notifiche utenti</h1>
        </div>
        <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
            <li class="breadcrumb-item active">Notifiche utenti</li>
            </ol>
        </div>
        </div>
        <div class="row mb-2">
        <div class="col-sm-6">
            <button type="button" class="btn btn-success" @click.prevent="newNotification()"><i class="fas fa-plus"></i> Nuovo </button>
        </div>
        </div>
    </div>
</section>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body" style="overflow-x: auto;">
                        <table-lite :is-slot-mode="true" :rows="notifications.rows" :columns="notificationTableCols" :total="notifications.totalRows" @do-search="doSearch" @row-clicked="rowClicked" :sortable="{}" id="tableNotifications" >
                        </table-lite>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>