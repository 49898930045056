<script setup>
import { useUsersStore } from '@/stores';
import { storeToRefs } from 'pinia';

const usersStore = useUsersStore();
const { users, isLoading } = storeToRefs(usersStore);

usersStore.getAll();

</script>

<template>

    <section class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1>Utenti</h1>
            </div>
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active">Utenti</li>
              </ol>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-6">
              <button type="button" class="btn btn-success" @click.prevent="newUser()"><i class="fas fa-plus"></i> Nuovo </button>
            </div>
          </div>
        </div>
    </section>

    <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-sm table-hover m-0">
                          <thead>
                            <tr>
                              <th>Nome</th>
                              <th>Cognome</th>
                              <th>Username</th>
                              <th></th>
                            </tr>
                          </thead>
                        <tbody>
                            <tr v-for="user in users">
                                <td>{{ user.first_name }}</td>
                                <td>{{ user.last_name }}</td>
                                <td>{{ user.email }}</td>
                                <td>
                                  <div class="text-center">
                                      <i class="fa fa-edit" @click.prevent="showDetail(user.id)"></i> <i class="fa fa-trash" @click.prevent="usersStore.delete(user.id)" ></i>
                                  </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
              </div>
              </div>
            </div>
          </div>
        </div>
    </section>
    
</template>

<script>
export default {
    data() {
        return {

        }
    },
    updated() {
        //usersStore.getAll();
    },
    methods: {
        newUser() {
            this.$router.push({ path: '/admin/users/create'});
        },
        showDetail(id) {
            this.$router.push({ path: '/admin/users/' + id });
        }
    }
}
</script>
