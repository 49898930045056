import { defineStore } from 'pinia';
import { fetchWrapper } from '../_helpers/fetch-wrapper';

const baseUrl = `${import.meta.env.VITE_API_URL}/journal`;
const newsListCatTree = JSON.parse(localStorage.getItem('newsListCatTree'))

export const useNewsStore = defineStore({
    id: 'news-state',
    state: () => ({
        categoryTree: {
            opened: newsListCatTree ? newsListCatTree.opened : []
        },
        newsList: Array,
        lastCat: String,
        newsDetail: Object,
        newsDetailImages: Object,
        newsDetailCats: Object
    }),
    actions: {
        setNewsLoading(isLoading) {
            if(isLoading)
                this.isNewsLoading = 'news-list spinner-grow';
            else
                this.isNewsLoading = 'news-list';
        },
        async fetchNewsCat(cat_id) {
            this.newsList = Array(0);
            const newsList = await fetchWrapper.get(`${baseUrl}/news?category=${cat_id}`);
            this.newsList = newsList.data.data.news;
            this.pager = newsList.data.links;
            
        },
        async saveNews() {
            const resp = await fetchWrapper.put(`${baseUrl}/news/${this.newsDetail.newspaper}-${this.newsDetail.uid}`, this.newsDetail);
            if(resp.status == "success") {
                this.newsList[this.newsDetail.uid] = this.newsDetail;
            }
        },
        persistState() {
            localStorage.setItem('newsListCatTree', JSON.stringify(this.categoryTree));
        },
        async fetchNewsDetail(news_id) {
            let parts = this.lastCat.split("-");
            const news = await fetchWrapper.get(`${baseUrl}/news?ids[]=${parts[0]}-${parts[1]}-${news_id}`);
            //this.newsDetail =  news.data.original.data[parts[0] + "-" + parts[1]][news_id].data['news'][news_id];
            this.newsDetail =  news.data[parts[0] + '-' + parts[1]]['news'][news_id];
            this.newsDetailImages = news.data[parts[0] + '-' + parts[1]]['images'];
            this.newsDetailCats = news.data[parts[0] + '-' + parts[1]]['categories'];
            this.newsDetail.newspaper = `${parts[0]}-${parts[1]}`;
        }
    }
});