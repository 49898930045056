<script setup>
import FolderItem from './FolderItem.vue';
import { ref } from "vue";

const baseUrl = `${import.meta.env.VITE_API_URL}`;

const props = defineProps({
    folders: Array,
    level: Number,
    filemount_id: String,
    opened: Boolean,
    parentPath: String,
    baseUrl: String,
    checkSelected: {
        type: Function,
        default: null,
    }
});

const openedStatus = ref(props.opened);

const emit = defineEmits([
    "load-news",
    "folderselect",
    "beforemount",
    "mounted",
    "opening",
    "folderIsSelected"
]);

const childBeforeMount = () => {
    emit('beforemount');
}

const childMounted = () => {
    emit('mounted');
}

const onFolderIsSelected = (path) => {
    openedStatus.value = true;
    emit('folderIsSelected', path);
}

const childFolderSelect = (path) => {
    emit('folderselect', path);
}

const childOpening = (status) => {
    emit('opening', status);
}

</script>


<template>
      <ul class="folders" :class="(openedStatus ? 'opened' : 'closed')">
      <template v-for="(folder) in folders" :key="parentPath + '/' + folder.name">
        <FolderItem 
            :checkSelected="checkSelected"
            :baseUrl="baseUrl"
            :parentPath="parentPath"
            :folder="folder" 
            :level=level+1  
            :filemount_id="filemount_id"
            @beforemount="childBeforeMount" 
            @mounted="childMounted" 
            @folderIsSelected="onFolderIsSelected" 
            @folderselect="childFolderSelect" 
            @opening="(status) => childOpening(status)"
         />
      </template>
  </ul>
</template>

