import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { fetchWrapper } from '../_helpers/fetch-wrapper';
import { useRoute } from 'vue-router'
import { useNewsPaperStore } from './newspaper.store';
const baseUrl = `${import.meta.env.VITE_API_URL}/journal`;

const draftListCatTree = JSON.parse(localStorage.getItem('draftListCatTree'))

export const useDraftStore = defineStore({
    id: 'draft-state',
    state: () => ({
        categoryTree: {
            opened: draftListCatTree ? draftListCatTree.opened : []
        },
        isDraftLoading: ref(''),
        draftList: ref({}),
        lastCat: ref(''),
        selectedLanguage: ref(0),
        draftDetail: new Object({
            positions: new Array(0),
            related: new Array(0),
        }),
        draftDetailImages: new Object({
            images: null,
            deleted_media: new Array(0),
        }),
        draftDetailFiles: new Object({
            files: new Array(0),
            deleted_media: new Array(0),
        }),
        publishing: ref({}),
        draftDetailCats: new Object(),
        publishingCats: new Array(0),
        publishUpload: ref({}),
        mustSaveDraft: ref(false),
        startUpload: ref(false),
    }),
    actions: {
        initPublish() {
            this.publishing = ref({})
        },
        setDraftLoading(isLoading) {
            this.isDraftLoading = isLoading;
        },
        fetchDraftNewspaper(newspaper_id, offset=0, limit=10, search="", quickfilter="all") {
            return new Promise((resolve, reject) => {
                fetchWrapper.get(`${baseUrl}/allnews?newspaper=${newspaper_id}&page=${(offset / limit + 1)}&limit=${limit}&search=${search}&language_id=${this.selectedLanguage}&type=draft&quickfilter=${quickfilter}`).then((response) => {
                    this.draftList = response.data;
                    resolve();
                }).catch((reason) => {
                    this.draftList = {};
                    reject("Impossibile caricare l'elenco delle notizie dal server. Codice errore: " + reason);
                }).finally(() => {
                    this.pager = this.draftList.links;
                });
            });
        },
        fetchRelatingDraft(newspaper_id, language_id = 0, offset=0, limit=10, search="", except=[], types="") {
            return new Promise((resolve, reject) => {
                fetchWrapper.get(`${baseUrl}/allnews?newspaper=${newspaper_id}&page=${(offset / limit + 1)}&limit=${limit}&search=${search}&language_id=${language_id}&except=${except}&type=${types}`).then((response) => {
                    resolve(response);
                }).catch((reason) => {
                    reject("Impossibile caricare l'elenco delle notizie dal server. Codice errore: " + reason);
                }).finally(() => {
                });
            });
        },
        async fetchDraftCat(cat_id) {
            this.draftList = {};
            const draftList = await fetchWrapper.get(`${baseUrl}/draft?category=${cat_id}`);
            this.draftList = draftList.data;
            this.pager = draftList.links;
        },
        async publishDraft() {
            // First save Draft
            this.saveDraft();
            
        },
        async saveAndClean() {
            await this.saveDraft()
            // Reset local store
            this.resetLocalStore();
        },
        deleteDraft(draftId) {
            return new Promise((resolve, reject) => {
                fetchWrapper.delete(`${baseUrl}/draft/${draftId}`).then(() => {
                    resolve();
                }).catch((reason) => {
                    reject("Impossibile eliminare la notizia dal server. Codice errore: " + reason);
                });
            });
        },
        async uploadImage(file, override = false) {
            console.log("Upload image", file);
            if(!this.draftDetail.uuid) {
                this.startUpload = false;
                this.mustSaveDraft = true;
                return this.enqueueImage(file, override)
            }
            let formData = new FormData();
            formData.set('image', file);
            formData.set('override', override);
            return new Promise( ( resolve, reject ) => {
                fetchWrapper.post(`${baseUrl}/draft/${this.draftDetail.uuid}/inline`, formData).then( (resp) => {
                    resolve({
                        default: '/draft-images/' + resp.path
                    });
                });
            });
        },
        enqueueImage(file, override) {
            return new Promise( (resolve, reject) => {
                let formData = new FormData();
                formData.set('image', file);
                formData.set('override', override);
                watch( () => this.startUpload, (val) => {
                    if(val) {
                        fetchWrapper.post(`${baseUrl}/draft/${this.draftDetail.uuid}/inline`, formData).then( (resp) => {
                            resolve({
                                default: '/draft-images/' + resp.path
                            });
                        });
                    }
                })
            });
        },

        async saveDraft() {
            const newsPaperStore = useNewsPaperStore();
            let resp;
            this.draftDetail.newspaper_id = newsPaperStore.selectedNewspaper;
            // Sort related news
            if(this.draftDetail.uuid) {
                resp = await fetchWrapper.put(`${baseUrl}/draft/${this.draftDetail.uuid}`, this.draftDetail);
            } else {
                resp = await fetchWrapper.post(`${baseUrl}/draft`, this.draftDetail);
                this.$router.replace({
                    name: 'draft-edit',
                    params: {
                        newspaperid: this.$router.currentRoute.value.params.newspaperid,
                        uuid: resp.data.uuid
                    }
                });
            }
            
            if(resp.status == "success") {
                this.draftDetail = resp.data;
                //this.startUpload = true;
                //this.mustSaveDraft = false;
            }

            let sorting = [];
            let waiting = [];

            let imageErrors = [];
            // Attachment images
            if(Array.isArray(this.draftDetailImages.images)) {
                this.draftDetailImages.images.forEach( (element, index) => {
                    if(element.uuid) {
                        // Saved image
                        sorting.push({"uuid":element.uuid, "sorting": index, "caption": element.caption});
                        element.sorting = index;
                    } else if (element.type == "remote") {
                        const prom = fetchWrapper.post(`${baseUrl}/draft/${this.draftDetail.uuid}/images`, element).then(data => {
                            sorting.push({"uuid": data.data.uuid, "sorting": index, "caption": element.caption});
                            element.uuid = data.data.uuid;
                            element.sorting = index;
                        }).catch(function(error) {
                            console.log("Error setting remote image:", element, error);
                            imageErrors.push(error);
                        });
                        waiting.push(prom);
                    } else {
                        // Added image
                        let formData = new FormData
                        //let url = URL.createObjectURL(files[i])
                        formData.set('image', element.orig)
                        const prom = fetchWrapper.post(`${baseUrl}/draft/${this.draftDetail.uuid}/images`, formData).then(data => {
                            sorting.push({"uuid": data.data.uuid, "sorting": index, "caption": element.caption});
                            element.uuid = data.data.uuid;
                            element.sorting = index;
                        }).catch(function(error) {
                            console.log("Error uploading local image:", element, error);
                            imageErrors.push(error);
                        });
                        waiting.push(prom);
                    }
                });
            }

            let that = this;
            await Promise.all(waiting).then((values) => {
                if(imageErrors.length > 0) {
                    throw new Error("Error saving images");
                }
                fetchWrapper.put(`${baseUrl}/draft/${that.draftDetail.uuid}/images`, {"sorting":sorting, "deleted": that.draftDetailImages.deleted_media})
            });

            // Attachment files
            sorting = [];
            waiting = [];
            if(Array.isArray(this.draftDetailFiles.files)) {
                this.draftDetailFiles.files.forEach( (element, index) => {
                    if(element.uuid) {
                        // Saved file
                        sorting.push({"uuid":element.uuid, "sorting": index});
                        element.sorting = index;
                    } else if (element.type == "remote") {
                        const prom = fetchWrapper.post(`${baseUrl}/draft/${this.draftDetail.uuid}/files`, element).then(data => {
                            sorting.push({"uuid": data.data.uuid, "sorting": index});
                            element.uuid = data.data.uuid;
                            element.sorting = index;
                        });
                        waiting.push(prom);
                    } else {
                        // Added file
                        let formData = new FormData
                        //let url = URL.createObjectURL(files[i])
                        formData.set('file', element.orig)
                        const prom = fetchWrapper.post(`${baseUrl}/draft/${this.draftDetail.uuid}/files`, formData).then(data => {
                            sorting.push({"uuid": data.data.uuid, "sorting": index});
                            element.uuid = data.data.uuid;
                            element.sorting = index;
                        });
                        waiting.push(prom);
                    }
                });
            }

            await Promise.all(waiting).then((values) => {
                fetchWrapper.put(`${baseUrl}/draft/${that.draftDetail.uuid}/files`, {"sorting":sorting, "deleted": that.draftDetailFiles.deleted_media})
            });

        },
        async fetchDraftDetail(news_id) {
            this.resetLocalStore();
            const news = await fetchWrapper.get(`${baseUrl}/draft?uuids[]=${news_id}`);
            let dates = ['datetime', 'starttime', 'endtime', 'boxed_from', 'boxed_to'];
            dates.forEach(date => {
                if(!news.data[news_id][date]) {
                    news.data[news_id][date] = undefined;
                } else {
                    news.data[news_id][date] = new Date(news.data[news_id][date]);
                }
            });

            //this.newsDetail =  news.data.original.data[parts[0] + "-" + parts[1]][news_id].data['news'][news_id];
            this.draftDetail =  news.data[news_id];

            this.draftDetailImages.images = news.data[news_id]['image'] || new Array(0);
            this.draftDetailFiles.files = news.data[news_id]['file'] || new Array(0);
            this.draftDetailCats = news.data[news_id]['category'];
            this.draftDetail.newspaper = this.draftDetail.newspaper_id;
            // Language
            const newspaperStore = useNewsPaperStore();
            try {
                const langs = newspaperStore.getNewspaperLangs(this.draftDetail.newspaper);
            } catch(e) {
                console.log("Error getting newspaper languages", e);
            }

        },
        async publish() {
           let resp = await fetchWrapper.post(`${baseUrl}/draft/${this.draftDetail.uuid}/publish`, {publishing: this.publishing, upload: this.publishUpload});
           return resp;
        },
        persistState() {
            localStorage.setItem('draftListCatTree', JSON.stringify(this.categoryTree));
        },
        resetLocalStore() {
            this.draftDetail = new Object({
                positions: new Array(0),
                related: new Array(0),
                langInfo: new Array(0),
                language_id: 0,
                datetime: new Date(),
            });
            this.draftDetailImages = new Object({
                images: new Array(0),
                deleted_media: new Array(0),
            });
            this.draftDetailFiles = new Object({
                files: new Array(0),
                deleted_media: new Array(0),
            });
            this.draftDetailCats = new Object;
        }
    }
});
