<script setup>
  const emit = defineEmits(['confirm', 'cancel'])
  const props = defineProps({
    title: "Conferma azione",
    message: "Sei veramente sicuro?"
  })
</script>


<template>
    <div class="confirm-container">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ props.title }}</h4>
            </div>
            <div class="modal-body">
                {{ props.message }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" aria-label="Close" @click="emit('cancel')">Cancel</button>
                <button type="button" class="btn btn-success" aria-label="Close" @click="emit('confirm')">OK</button>
            </div>
            </div>
        </div>
    </div>
</template>
