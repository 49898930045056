import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export class RteLink extends Plugin {
    init() {
        const editor = this.editor;

        editor.model.schema.extend( '$text', { allowAttributes: ['linkExternal', 'linkTarget', 'linkClass', 'linkTitle'] } );

        // linkExternal
        editor.conversion.for( 'downcast' ).attributeToElement( {
            model: 'linkExternal',
            view: ( attributeValue, { writer } ) => {
                return writer.createAttributeElement( 'a', { external: attributeValue }, { priority: 5 } );
            },
            converterPriority: 'low'
        } );

        editor.conversion.for( 'upcast' ).attributeToAttribute( {
            view: {
                name: 'a',
                key: 'external'
            },
            model: 'linkExternal',
            converterPriority: 'low'
        } );

        // linkTarget
        editor.conversion.for( 'downcast' ).attributeToElement( {
            model: 'linkTarget',
            view: ( attributeValue, { writer } ) => {
                return writer.createAttributeElement( 'a', { target: attributeValue }, { priority: 5 } );
            },
            converterPriority: 'low'
        } );

        editor.conversion.for( 'upcast' ).attributeToAttribute( {
            view: {
                name: 'a',
                key: 'target'
            },
            model: 'linkTarget',
            converterPriority: 'low'
        } );

        // linkClass
        editor.conversion.for( 'downcast' ).attributeToElement( {
            model: 'linkClass',
            view: ( attributeValue, { writer } ) => {
                return writer.createAttributeElement( 'a', { class: attributeValue }, { priority: 5 } );
            },
            converterPriority: 'low'
        } );

        editor.conversion.for( 'upcast' ).attributeToAttribute( {
            view: {
                name: 'a',
                key: 'class'
            },
            model: 'linkClass',
            converterPriority: 'low'
        } );

        // linkTitle
        editor.conversion.for( 'downcast' ).attributeToElement( {
            model: 'linkTitle',
            view: ( attributeValue, { writer } ) => {
                return writer.createAttributeElement( 'a', { title: attributeValue }, { priority: 5 } );
            },
            converterPriority: 'low'
        } );

        editor.conversion.for( 'upcast' ).attributeToAttribute( {
            view: {
                name: 'a',
                key: 'title'
            },
            model: 'linkTitle',
            converterPriority: 'low'
        } );
    }
}