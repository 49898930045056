import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/views/layouts/NewspaperLayout.vue';
import Home from '@/views/Home.vue';

import { useAuthStore } from '@/stores';

import accountRoutes from './account.routes';
/* import newsRoutes from './news.routes'; */
import draftRoutes from './draft.routes';
import adminRoutes from './admin.routes';
//import publishedRoutes from './published.routes';
import mediaRoutes from './media.routes';
import userRoutes from './user.routes';


export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    linkActiveClass: 'active',
    routes: [
        { 
            path: '/',
            name: 'home',
            component: Layout,
            children: [
                {
                    path: '',
                    name: "home-dashboard",
                    component: Home,
                },
            ]
        },
        { ...accountRoutes },
        /* { ...newsRoutes }, */
        { ...draftRoutes },
        { ...adminRoutes },
        /* { ...publishedRoutes }, */
        { ...mediaRoutes },
        { ...userRoutes },
        // catch all redirect to home page
        { path: '/:pathMatch(.*)*', redirect: '/' }
    ]
});

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page 
    const publicPages = ['/account/login'];
    const authRequired = !publicPages.includes(to.path);
    const authStore = useAuthStore();

    if (authRequired && !authStore.user) {
        // Force Home Page after re-login
        authStore.returnUrl = to.fullPath;
        return '/account/login';
    }
    if (authRequired) {
        try {
            await authStore.token_valid();
        } catch(e) {
            return '/account/login';
        }
    }
    /*
    var roles = authStore.user.roles;
    if( roles.includes("User Manager") ) {
        return '/admin/users';
    }
    */
});