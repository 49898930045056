<script setup>
import { useNewsStore, useNewsPaperStore } from '@/stores';
const newsStore = useNewsStore();
const newsPaperStore = useNewsPaperStore();

</script>

<template>
        <img :src="getImageUrl()" width="150" /><span></span>
        <i class="fa fa-trash" @click="remove()"></i>
</template>

<script>

export default {
  props: {
    news_id: String,
    dam_uid: String
  },
  methods: {
    remove() {
        this.newsStore.newsDetail.dam_images.filter(function(value, index, arr) { 

        });
        
        this.newsStore.newsDetail.dam_images.forEach(element => {
            console.log(element);
        });
    },
    getImageUrl() {
        let parts = this.newsStore.lastCat.split("-");
        let newsp = this.newsPaperStore.newspaper[parts[0] + '-' + parts[1]];
        
        let url = 'https://' + newsp.main_domain + '/' + this.newsStore.newsDetailImages[this.dam_uid].file_path + this.newsStore.newsDetailImages[this.dam_uid].file_name;
        //url = 'https://giornali02.esprimo.com/' + this.newsStore.newsDetailImages[this.dam_uid].file_path + this.newsStore.newsDetailImages[this.dam_uid].file_name;
        return url;
    }
  }
}
</script>
