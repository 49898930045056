import { useAuthStore } from '@/stores';
import { notify } from "@kyvg/vue3-notification";
import { useLoading } from '@/loader';
const baseUrl = `${import.meta.env.VITE_API_URL}`;

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE')
};

const loader = useLoading();

function authHeader(url) {
    return new Promise((resolve, reject) => {
        // return auth header with jwt if user is logged in and request is to the api url
        const authStore = useAuthStore();
        const isLoggedIn = !!authStore.user?.access_token;
        const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL);
        if (isLoggedIn && isApiUrl) {
            let limit = authStore.user.exp - (60 * 60 * 1000); // Forza il refresh 1 ora prima della scadenza
            if(authStore.user?.iat > 0) {
                limit = (authStore.user.exp - authStore.user.iat) / 2 + authStore.user.iat; // Forza il refresh se passata la metà del tempo di validità del token
            }
            if(new Date().getTime() >= limit ) {
                console.log("Token is about to expire: refresh");
                // Refresh Token
                const requestOptions = {
                    method: 'POST',
                    headers: { Authorization: `Bearer ${authStore.user.access_token}` }
                };
                fetch(baseUrl + '/refresh', requestOptions).then(response => {
                    response.json().then(data => {
                        authStore.user.access_token = data.token;

                        var base64Url = data.token.split('.')[1];
                        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                        var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        }).join(''));
                        var token_data = JSON.parse(jsonPayload);
                        authStore.user.exp = token_data.exp * 1000; // expiration
                        authStore.user.iat = token_data.iat * 1000; // issued at
                        // store user details and jwt in local storage to keep user logged in between page refreshes
                        localStorage.setItem('user', JSON.stringify(authStore.user));

                        resolve({ Authorization: `Bearer ${authStore.user.access_token}` });
                    }).catch(reason => {
                        authStore.logout();
                        reject(reason);
                    });
                }).catch(reason => {
                    authStore.logout();
                    reject(reason);
                });
            } else {
                resolve({ Authorization: `Bearer ${authStore.user.access_token}` });
            }
        } else {
            resolve({});
        }
    });
}

function request(method) {

    return (url, body) => {
        return new Promise((resolve, reject) => {
            authHeader(url).then( (value) => {
                const requestOptions = {
                    method,
                    headers: value
                };
                if (body) {
                    if(body instanceof FormData) {
                        requestOptions.body = body;
                    } else {
                        requestOptions.headers['Content-Type'] = 'application/json';
                        requestOptions.body = JSON.stringify(body);
                    }
                }
                fetch(url, requestOptions).then((data) => {
                    handleResponse(data).then( (data) => {
                        resolve(data);
                    }).catch( (reason) => { 
                        reject(reason);
                    });
                }).catch( (reason) => {
                    loader.hide();
                    reject(reason);
                });
            }).catch(reason => {
                loader.hide();
                reject('Sessione scaduta');
            });
        });

    }
}

// helper functions



async function handleResponse(response) {
    const isJson = response.headers?.get('content-type')?.includes('application/json');
    const data = isJson ? await response.json() : null;

    // check for error response
    if (!response.ok) {
        const { user, logout } = useAuthStore();
        if ([401].includes(response.status) && user) {
            // Try refresh Token
            console.log("401 response", response);
            console.log("Response data", data);
            loader.hide();
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            logout();
            notify({
                text: "session-expired",
                type: 'error',
            });
        }

        // get error message from body or default to response status
        const error = (data) || response.status;
        return Promise.reject(error);
    }
    return data;
}