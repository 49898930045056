<script setup>
import { ref } from 'vue';
import CategoryItem from './CategoryItem.vue';

</script>

<template>
  <ul class="categories" :class="(opened ? 'opened' : 'closed')">
      <template v-for="(category) in categories">
        <CategoryItem :openedTree="openedTree" :store="store" :republish="republish" :publish="publish" :category="category" :level=level+1 :opened="(level==1)"  :newspaper_id="newspaper_id" @catselect="childCatSelect" @togglecat="toggleCat" />
      </template>
  </ul>
</template>

<script>
const baseUrl = `${import.meta.env.VITE_API_URL}`;

export default {
    emits: [
        "load-news",
        "catselect",
        "togglecat"
    ],
    props: {
        categories: Array,
        level: Number,
        newspaper_id: String,
        opened: Boolean,
        publish: ref(false),
        republish: ref(false),
        openedTree: {
            type: Array,
            default: []
        },
        store: null,
    },
    data() {
        return {
            subOpened: false,
        };
    },
    methods: {
        toggleCat(value) {
            this.$emit('togglecat', value);
        },
        toggleStatus(event) {
            this.subOpened = !this.subOpened;
        },
        catSelect(cat_id) {
            let newCat = this.newspaper_id + "-" + cat_id;
            this.$emit("catselect", newCat);
        },
        childCatSelect(cat_id) {
            this.$emit("catselect", cat_id);
        }
    },
    components: { CategoryItem }
}
</script>
