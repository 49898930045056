<script setup>
import { useNewsPaperStore } from '@/stores';
import { computed, nextTick, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useBackendStore } from '../stores/backend.store';
import { useAuthStore } from '../stores';
import { useLoading } from '@/loader/index';
import { useNotification } from "@kyvg/vue3-notification";

const router = useRouter();
const authStore = useAuthStore();
const store = useNewsPaperStore();
const backendStore = useBackendStore();
const loader = useLoading();
const { notify } = useNotification();
// redirect home if already logged in

const emit = defineEmits(['newspaperselect']);

const props = defineProps({
  persist: Boolean,
  newsStore: Object
});

onMounted(() => {
  if(router.currentRoute.value.params.newspaperid) {
    store.selectedNewspaper = router.currentRoute.value.params.newspaperid;
  }
  if (!store.newspaper || Object.keys(store.newspaper).length == 0) {
    loader.show();
    store.get().catch( error => {
      notify({
        type: 'error',
        title: 'Errore nel caricamento dei giornali',
        text: error
      });
    }).finally(() => {
      loader.hide();
    });
  }
});

onUnmounted(() => {
  store.persistState();
});

const backendClass = computed( () => {
  return (backend_id) => {
    const parts = store.selectedNewspaper.split('-');
    if(parts[0] == backend_id && !isPreferredSelected()) {
      return 'menu-open';
    } else {
      return '';
    }
  }
});

function isPreferredSelected() {
  if(authStore.user.preferences.newspapers.length > 0) {
    if(authStore.user.preferences.newspapers.indexOf(store.selectedNewspaper) > -1) {
      return true;
    }
  }
  return false;
}

function selectPreferredNewspaper(newspaper_id) {
  emit('newspaperselect', newspaper_id);
}

function selectNewspaper(newspaper_id) {
  emit('newspaperselect', newspaper_id);
}

function selectBackend(backend_id) {
  selectNewspaper(backend_id + "-0");
}

const preferredNewspapers = computed( () => {
  let newspapers = [];
  if(authStore.user?.preferences?.newspapers?.length > 0 && store.newspaper && Object.keys(store.newspaper).length > 0) {
    const filtered = authStore.user.preferences.newspapers.filter( (newspaper_id) => {
      let parts = newspaper_id.split('-');
      if(parts[0] in store.newspaper) {
        for(const [name, newspaper] of Object.entries(store.newspaper[parts[0]])) {
          if(newspaper.uid == parts[1]) {
            return true;
          }
        }
      }
      return false;
    });
    newspapers = filtered;
  }

  return newspapers;
});

</script>

<template>
    <nav class="mt-2">
      <template v-if="preferredNewspapers.length > 0">
      <ul class="nav nav-pills nav-sidebar">
 
        <li class="nav-item" v-for="newspaper_id in preferredNewspapers">
          <a  class="nav-link" :class="( newspaper_id == store.selectedNewspaper)?'active bg-secondary':''" @click.prevent="selectPreferredNewspaper(newspaper_id)" >
            <!--<i class="far fa-circle nav-icon"></i>-->
            <i class="far fa-star nav-icon"></i>
            <p>{{ store.getNewspaperName(newspaper_id) }}</p>
          </a>
        </li>  
      </ul>
      <hr>
      </template>
      <ul class="nav nav-pills nav-sidebar flex-column nav-compact" role="menu" data-accordion="true">
        <template v-if="authStore.user">
          <li class="nav-item" :class="backendClass(backend_id)" v-for="(backend, backend_id) in store.newspaper" :key="backend_id">
            <a href="#" class="nav-link" :class="( store.selectedNewspaper.startsWith(`${backend_id}-`) )?'active':''" @click.prevent="selectBackend(backend_id)">
              <i class="nav-icon fas fa-book"></i>
              <p>
                {{ backendStore.getBackendName(backend_id) }}
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li class="nav-item" v-for="(newspaper, newspaper_id) in backend">
                <a  class="nav-link" :class="( `${backend_id}-${newspaper.uid}` == store.selectedNewspaper)?'active bg-secondary':''" @click.prevent="selectNewspaper(`${backend_id}-${newspaper.uid}`)" >
                  <i class="far fa-circle nav-icon"></i>
                  <p>{{ newspaper.name }}</p>
                </a>
              </li>
            </ul>
          </li>
        </template>

      </ul>
    </nav>
</template>

<style>

ul.newspaper-list li.newspaper-item .newspaper.active {
  font-weight: bold;
}

ul.newspaper-list,
ul.categories {
  padding-inline-start: 18px;
}
div > ul.newspaper-list,
div > ul.categories {
  padding-inline-start: 0px;
}

ul.categories {
  /*font-size: 14px;*/
}

.category-item span.category {
  cursor:pointer;
}
.category-item.selected > span.category {
  font-weight:bold;
}
</style>
