<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useLoading } from '@/loader';
import { useNotification } from '@kyvg/vue3-notification';
import { fetchWrapper } from '@/_helpers/fetch-wrapper';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import editorConfig from '@/ckeditor5/config/minimal';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const baseUrl = `${import.meta.env.VITE_API_URL}/admin/usernotifications`;

const loader = useLoading();
const router = useRouter();
const { notify }  = useNotification();

// v-model
const notification = ref({
    title: "",
    summary: "",
    description: "",
    type: "generic",
    from: new Date(),
    to: null
});

// Generic configurations
const notificationTypes = [
    {
        id: 'generic',
        label: 'Generale'
    }, {
        id: 'releasenote',
        label: 'Release Note'
    }
];

const datepickerConfig = {
    dateFormat: "yyyy-MM-dd hh:mm",
    textInputOptions: {
        format: "dd/MM/yyyy HH:mm"
    },
};

function onEditorReady() {

}

function onCancel() {
    router.push({ path: '/admin/notifications'});
}

function onSave() {
    if(router.currentRoute.value.params.id) {
        updateNotification();
    } else {
        createNotification();
    }
}

function createNotification() {
    loader.show();
    fetchWrapper.post(baseUrl, notification.value).then( (result) => {
        notify({
            type: 'success',
            title: "Salvataggio completato"
        });
        router.push({ path: '/admin/notifications'});
    }).catch( (reason) => {
        console.warn(reason);
        notify({
            type: 'danger',
            title: "Errore nel salvataggio.",
            text: "Errore: " + reason
        });
    }).finally( () => {
        loader.hide();
    });
}

function updateNotification() {
    loader.show();
    fetchWrapper.put(baseUrl + '/' + router.currentRoute.value.params.id, notification.value).then( (result) => {
        notify({
            type: 'success',
            title: "Salvataggio completato"
        });
        router.push({ path: '/admin/notifications'});
    }).catch( (reason) => {
        console.warn(reason);
        notify({
            type: 'danger',
            title: "Errore nel salvataggio.",
            text: "Errore: " + reason
        });
    }).finally( () => {
        loader.hide();
    });
}

function init() {
    if(router.currentRoute.value.params.id) {
        loader.show();
        fetchWrapper.get(baseUrl + '/' + router.currentRoute.value.params.id).then( (result) => {
            notification.value = result.data;
        }).catch( (reason) => {
            console.warn(reason);
        }).finally( () => {
            loader.hide();
        });
    }
}

init();

</script>

<template>
<section class="content-header">
    <div class="container-fluid">
    <div class="row mb-2">
        <div class="col-sm-6">
        <h1>Modifica notifica</h1>
        </div>
        <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active">Modifica notifica</li>
            </ol>
        </div>
    </div>
    </div>
</section>
<section class="content">
    <div class="container-fluid">
        <div class="card card-default">
            <form class="notification m-3">
                <div class="form-group">
                    <label for="title">Titolo</label> 
                    <input class="form-control" v-model="notification.title" id="title">
                </div>
                <div class="form-group summary">
                    <label for="summary">Riepilogo</label> 
                    <ckeditor id="summary" :editor="ClassicEditor" v-model="notification.summary" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group description">
                    <label for="description">Descrizione</label>
                    <ckeditor id="description" :editor="ClassicEditor" v-model="notification.description" :config="editorConfig"></ckeditor>
                </div>
                <div class="form-group">
                    <label for="type">Tipo</label> 
                    <v-select id="type" v-model="notification.type" :options="notificationTypes" label="label" :reduce="(el) => el.id"></v-select>
                </div>
                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="valid-from">Data inizio</label>
                        <datepicker id="valid-from" v-model="notification.from" :inputFormat="datepickerConfig.dateFormat" :text-input="datepickerConfig.textInputOptions"  locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']"  />
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="valid-to">Data fine</label>
                        <datepicker id="valid-to" v-model="notification.to" :inputFormat="datepickerConfig.dateFormat" :text-input="datepickerConfig.textInputOptions"  locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']"  />
                    </div>
                    </div>
                </div>
                <div class="mt-3 mb-2 row justify-content-end gx-5">
                    <div class="col-auto">
                        <button class="btn btn-secondary" @click.prevent="onCancel()">Chiudi</button>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary" @click.prevent="onSave()">Salva</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
</template>

<style>
form.notification .summary .ck-content.ck-editor__editable {
    min-height: 100px;
}

form.notification .description .ck-content.ck-editor__editable {
    min-height: 300px;
}
</style>