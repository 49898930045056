<script setup>
import { useLoading } from '@/loader';
import DamImageBrowserVue from '../../components/newspaper/DamImageBrowser.vue';
import FolderTree from '../../components/newspaper/FolderTree.vue';

</script>

<template>
    <div class="row pt-4">
        <div class="col">
            <DamImageBrowserVue context="none" />
        </div>
    </div>
</template>

<script>
  export default {
    data() {
        return {};
    },
    methods: {
        async catSelect(cat_id) {
            // From CategoryTree
            if (cat_id != this.newsStore.lastCat) {
                const loader = useLoading();
                loader.show();
                await this.newsStore.fetchNewsCat(cat_id);
                loader.hide();
                this.newsStore.lastCat = cat_id;
            }
        }
    },
    components: { FolderTree }
}
</script>