import Plugin from '@ckeditor/ckeditor5-core/src/plugin';

export class RteVideo extends Plugin {
    init() {
        const editor = this.editor;

        /*
        editor.conversion.for( 'dataDowncast' )
            .add( dispatcher => dispatcher.on( 'attribute:url:media', ( event, data, conversionApi ) => {
                console.log("dataDowncast insert:media event", event);
                console.log("dataDowncast insert:media data", data);
                console.log("dataDowncast insert:media conversionApi", conversionApi);

                console.log("Create Element");
                const el = conversionApi.writer.createEditableElement( 'img', { 
                    ref: data.item.getAttribute('url'), 
                    type: data.item.getAttribute('type'),
                    width: data.item.getAttribute('width'),
                    height: data.item.getAttribute('height'),
                    src: data.item.getAttribute('preview')
                });
                console.log("Consume insert");
                conversionApi.consumable.consume( data.item, 'insert' );
                console.log("Bind Elements");
                conversionApi.mapper.bindElements( data.item, el );
                console.log("Insert Elements");
                conversionApi.writer.insert(
                    conversionApi.mapper.toViewPosition( data.range.start ),
                    el
                );
            } ));
        */

        /*
        console.log("RteVideo dataDowncast");
        editor.conversion.for( 'dataDowncast' ).elementToStructure( {
            model: 'media',
            view: ( modelElement, { writer } ) => {
                return writer.createEditableElement(
                    'img',
                    { ref: modelElement.getAttribute('url'), type: modelElement.getAttribute('type')}
                );
            },
            converterPriority: 'high'
        } );
        */

        editor.conversion.for( 'upcast' ).elementToElement( {
            view: {
                name: 'img',
                attributes: ['type', 'src', 'ref', 'width', 'height']
            },
            model: (viewElement, {writer}) => {
                const type = viewElement.getAttribute('type');
                let element = null;
                if(type == 'html') {
                    element = writer.createElement('rawHtml', { value: atob(viewElement.getAttribute('ref'))});
                } else {
                    element = writer.createElement('media', { 
                        url: viewElement.getAttribute('ref'), 
                        type: type, 
                        width: viewElement.getAttribute('width'),
                        height: viewElement.getAttribute('height'),
                        preview: viewElement.getAttribute('src')
                    });
                }
                return element;
            },
            converterPriority: 'high'
        } );
    }
}