<script setup>
import { nextTick, ref, watch, computed } from 'vue';
import { useNewsPaperStore, usePublishedStore, useAuthStore } from '../../stores';
import TableLite from '@/components/table-lite';
import { useLoading } from '@/loader';
import { useRouter } from 'vue-router';
import { useNotification } from '@kyvg/vue3-notification';
import { format, parseJSON } from 'date-fns';

const props = defineProps(['show', 'language', 'remote_id']);
const emit = defineEmits(['closed', 'hidebsmodal', 'addrelated']);

const loader = useLoading();
const router = useRouter();
const publishedStore = usePublishedStore();
const newsPaperStore = useNewsPaperStore();
const authStore = useAuthStore();
const { notify }  = useNotification();

// Internal state
const remoteList = ref({data:[]});
const searchTerm = ref("");
const selectedRemotes = ref([]);

// Table config
const dateFormat = "dd/MM/yyyy HH:mm";
const tableCols = [
    { label: "ID", width: "0%", field: "uid", isKey: true, display: false },
    { label: "Titolo", width: "70%", field: "title" },
    { label: "Data/ora", width: "15%", field: "datetime", display: (row) => { return formatDateTime(row.datetime) } },
];

// Template refs
const tableRemote = ref(null);
const relatedBrowser = ref(null);

const pageSize = computed({
  get() {
    return authStore.user.preferences.lastPreferences.news.relatedPageSize;
  },
  set(value) {
    if(authStore.user.preferences.lastPreferences.news.relatedPageSize != value) {
      authStore.user.preferences.lastPreferences.news.relatedPageSize = value;
      authStore.updatePreferences(authStore.user);
      clickSearch();
    }
  }
});

function formatDateTime(inputDate) {
  let date;
  try {
    date = format(parseJSON(inputDate), dateFormat);
  } catch(err) {
    date = '';
    console.log("Error formatting date", err);
  }
  return date;
}

function resetSelected() {
  console.log("tableRemote", tableRemote.value);
  //tableRemote.value.clearChecked();
  console.log("selectedRemotes", selectedRemotes);
}

function doSearch(offset, limit, order, sort) {
  loader.show({container: relatedBrowser.value });
  if (limit != pageSize.value) {
      pageSize.value = limit;
    }
  resetSelected();
  publishedStore.fetchRelatingRemote(router.currentRoute.value.params.newspaperid, offset, limit, searchTerm.value, [props.remote_id], "", props.language,)
  //publishedStore.fetchRelatingRemote(router.currentRoute.value.params.newspaperid, props.language, offset, limit, searchTerm.value, [props.remote_id], '')
  .then( (response) => {
    remoteList.value.data = response.data;
    remoteList.value.total = response.meta.totalResults;
  })
  .catch( (reason) => {
    notify({
        title: "Error",
        text: reason,
        type: 'error',
      });
  }).finally(() => {
    loader.hide();
  });
}


function addSelected() {
  // Find selected images
  selectedRemotes.value.forEach((remote, index) => {
    addRelated(remoteList.value.data.find(item => item.uid == remote));
  });
  closeBrowserModal(null);
}

function addRelated(remote) {
  emit('addrelated', remote);
}

function closeBrowserModal(e) {
    emit('hidebsmodal');
}

function updateCheckedRows(rowsKeys) {
  selectedRemotes.value = rowsKeys;
};

function clickSearch() {
  if(tableRemote.value.setting.page == 1) {
    doSearch(0, pageSize.value);
  } else {
    tableRemote.value.setting.page = 1;
  }
}

watch(() => props.show, (value) => {
    if (value) {
        setTimeout(() => {
            nextTick(() => {
                doSearch(0, pageSize.value);
            });
        },100);
    }
    else {
        emit('closed');
    }
});
</script>

<template>
    <div v-if="show" ref="relatedBrowser" class="container-fluid p-0">
        <div class="mb-3">
            <div class="input-group ">
            <input type="search" id="search" v-model="searchTerm" class="form-control" @search="clickSearch()" >
            <div class="input-group-append">
                <button class="btn btn-primary" @click.prevent="clickSearch()"><i class="fas fa-search"></i></button>
            </div>
            </div>
        </div>
        <button class="mb-2 btn btn-success" @click.prevent="addSelected">+ Aggiungi selezionati</button>
        <table-lite class="table table-hover table-responsive" :page-size="pageSize" :has-checkbox="true" checkedReturnType="key" @return-checked-rows="updateCheckedRows" :is-slot-mode="true" :rows="remoteList.data" :columns="tableCols" :total="remoteList.total" @do-search="doSearch" :sortable="{}" ref="tableRemote" ></table-lite>
        <button class="mb-2 btn btn-success" @click.prevent="addSelected">+ Aggiungi selezionati</button>
    </div>
</template>