import UserLayout from '@/views/layouts/UserLayout.vue';
import { UserProfile, UserNotifications } from '@/views/user';


export default {
    path: '/user',
    name: 'user',
    component: UserLayout,
    children: [
        { path: 'profile', name: 'user-profile', component: UserProfile },
        { path: 'notifications', name: "user-notifications", component: UserNotifications },
    ]
};