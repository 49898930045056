<script setup>
import { useLoading } from '@/loader';
import { useNewsPaperStore, usePublishedStore } from '@/stores';
import { onMounted, ref, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import Datepicker from '@vuepic/vue-datepicker';
import enabledConfig from '@/ckeditor5/config/enabled';
import { useNotification } from '@kyvg/vue3-notification';
import DamImageBrowser from '../../components/newspaper/DamImageBrowser.vue';
import DamImages from '../../components/newspaper/DamImages.vue';
import DamFiles from '../../components/newspaper/DamFiles.vue';
import PublishPublished from '../../components/newspaper/PublishPublished.vue';
import RelatedRemoteNews from '@/components/newspaper/RelatedRemoteNews.vue';
import RelatedRemoteBrowser from '../../components/newspaper/RelatedRemoteBrowser.vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
//import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

const loader = useLoading();
const publishedStore = usePublishedStore();
const newsPaperStore = useNewsPaperStore();
const { remoteDetail } = storeToRefs(publishedStore);
const route = useRoute();
const router = useRouter();

const { notify }  = useNotification();

// Template refs
const relatedBrowser = ref(null);
const damBrowser = ref(null);

// DateTime Picker
const textInputOptions = {
    format: "dd/MM/yyyy HH:mm"
};

let modalRelatedBrowser = null;
let modalDamBrowser = null;




const imgToEdit = ref(null);
const imageEditor = ref(null);
const damEditor = ref(null);
const damEditorModal = ref(null);

const onDamEdit = (media) => {
    openEditor(media, 'inline');
};

const onDamEditorClosing = () => {
    imgToEdit.value = null;
};


let imageEditorData = {
    mode: null,
    media: null,
    index: null
};

const rotate = (angle) => {
    imageEditor.value.rotate(angle);
    nextTick( () => {
        imageEditor.value.transitionsActive = false;
        imageEditor.value.setCoordinates({
            top: 0,
            left: 0,
            height: imageEditor.value.imageSize.height,
            width: imageEditor.value.imageSize.width
        });
    });
}

const flip = (x, y) => {
    const { image } = imageEditor.value.getResult();
    if (image.transforms.rotate % 180 !== 0) {
        imageEditor.value.flip(!x, !y);
    } else {
        imageEditor.value.flip(x, y);
    }
}

const defaultSize = ({ imageSize, visibleArea }) => {
    return {
        width: (imageSize || visibleArea).width,
        height: (imageSize || visibleArea).height,
    };
}

const saveImage = () => {
    const { canvas } = imageEditor.value.getResult();

    if(imageEditorData.mode == 'inline') {
        canvas.toBlob( (blob) => {
            let url = new URL(imageEditorData.media.getAttribute("src"), window.document.location.origin);
            const fileName = url.pathname.split('\\').pop().split('/').pop();
            let params = url.searchParams;
            let file = new File([blob], decodeURI(fileName));
            publishedStore.uploadImage(file, route.params.newspaperid, true).then( (response) => {
                let ckver = Number.parseInt(params.get("ckver"));
                if(isNaN(ckver)) {
                    ckver = 1;
                }
                bodytextEditor.value.instance.execute( 'replaceImageSource', { source: response.default + "?ckver=" + (ckver+1) } )
                imageEditorData.media = null;
                imageEditorData.mode = null;
            })
        })
    } else if(imageEditorData.mode == 'attachment') {

        canvas.toBlob( (blob) => {
                const fileName = imageEditorData.media.orig.name;
                let file = new File([blob], decodeURI(fileName));
                imageEditorData.media.url = URL.createObjectURL(file);
                imageEditorData.media.orig = file;
            });

    } else {
        console.warn("Save image editor mode not supported:", imageEditorData.mode);
    }
    damEditorModal.value.hide();
}

const closeImageEditor = () => {
    imageEditorData.media = null;
    imageEditorData.mode = null;
    imageEditorData.index = null;
    damEditorModal.value.hide();
}


const openEditor = (media, mode) => {
    
    imageEditorData.mode = mode;

    console.log("Media edit", media);
    if(mode == 'inline') {
        let src = media.getAttribute("src");
        if(src && src.startsWith("/published-images/")) {
            imgToEdit.value = src;
            imageEditorData.media = media;
        } else {
            console.log("Immagine remota inline non supportata", media);
        }
    } else if(mode == 'attachment') {
        if(media.is_remote) {
            // From remote
            console.warn("Immagine remota allegata non supportata", media);
        } else if(media.orig) {
            // From PC
            imgToEdit.value = media.url;
            imageEditorData.media = media;
            imageEditorData.index = publishedStore.draftDetailImages.images.indexOf(media);
        } else if(media.path) {
            // From draft
            imgToEdit.value = "/published-images/" + media.path;
            imageEditorData.media = media;
            imageEditorData.index = publishedStore.draftDetailImages.images.indexOf(media);
        }
    } else {
        console.warn("Modalità non supportata", mode);
    }

    damEditorModal.value.show();
}

// Component States
const editorData = ref("");
const editor = ClassicEditor
let bodytextEditorConfig = enabledConfig;
// Disattivo le immagini inline (per ora)
bodytextEditorConfig['dam-image']['click'] = onDamBrowse;
bodytextEditorConfig['dam-edit']['click'] = onDamEdit;
bodytextEditorConfig.ServerId = route.params.newspaperid.split("-")[0];
bodytextEditorConfig.NewspaperId = route.params.newspaperid;
bodytextEditorConfig.newsPaperStore = newsPaperStore;
/*
bodytextEditorConfig['toolbar']['items'] = bodytextEditorConfig['toolbar']['items'].filter((item, index) => {
    return !(item == 'uploadImage')
});
*/
onMounted( async () => {
    damEditorModal.value = new bootstrap.Modal(damEditor.value);
    damEditorModal.value._config.keyboard = false;
    $(damEditorModal.value._element).on('hide.bs.modal', onDamEditorClosing);

    modalDamBrowser = new bootstrap.Modal(damBrowser.value);
    modalDamBrowser._config.keyboard = false;
    $(modalDamBrowser._element).on('hide.bs.modal', onDamClosing);

    modalRelatedBrowser = new bootstrap.Modal(relatedBrowser.value);
    modalRelatedBrowser._config.keyboard = false;
    $(modalRelatedBrowser._element).on('hide.bs.modal', onRelatedClosing)
    await initRemoteDetail();
});


// Remote news
async function initRemoteDetail(remote_id) {
    loader.show();
    if(!remote_id && route.params.id) {
        remote_id = route.params.id;
    }
    //publishedStore.fetchRemotePublishedDetail(remote_id)
    publishedStore.fetchRemoteDetail(route.params.newspaperid, remote_id)
    .then((data) => {
        editorData.value = publishedStore.remoteDetail.bodytext;
        bodytextEditor.value.instance.draftId = publishedStore.remoteDetail.id;
        if(publishedStore.remoteDetail.starttime != null) {
            enabledDate.value = false;
        }
    }).catch((err) => {
        notify({
            text: "Errore nel caricamento della notizia pubblicata",
            type: "danger"
        });
        console.log("Error loading remote news", err);
    }).finally( () => {
        loader.hide();
    });
}

function closeNews() {
    router.push({ name: "draft-list-newspaper", params: { newspaperid: route.params.newspaperid } });
}

function saveNewsAndClose() {
    saveNews(false).then( (res) => {
        publishedStore.resetLocalStore();
        closeNews();
    });
}

function saveNews(refresh = true) {
    publishedStore.remoteDetail.bodytext = editorData;
    return new Promise((resolve, reject) => {
        try {
            loader.show();
            publishedStore.saveRemote(newsPaperStore.selectedNewspaper).then((data) => {
                /*
                published_report.value = data;
                activeTab.value = 'report';
                */
                if (refresh) {
                    initRemoteDetail(route.params.id).then( () => {
                        notify({
                            text: "Notizia pubblicata",
                            type: "success"
                        });
                        resolve();
                    });
                } else {
                    notify({
                        text: "Notizia pubblicata",
                        type: "success"
                    });
                    resolve();
                }

            }).catch((reason) => {
                notify({
                    text: "Errore nel salvataggio della notizia",
                    type: "error"
                });
                console.log(reason);
            }).finally(() => {
                loader.hide();
            });
        } catch (err) {
            notify({
                text: err.message,
                type: "error"
            });
            reject(e.message);
        }
    });
}

// CKEditor
const bodytextEditor = ref(null);

async function publishInlineImage(file) {
    return new Promise( ( resolve, reject ) => {
        publishedStore.uploadImage(file, route.params.newspaperid).then( (data) => {
            resolve(data);
        }).catch( (reason) => {
            reject(error);
        });
    });
}

function onBodytextEditorReady(evt) {
    bodytextEditor.value.instance.model.on('_afterChanges', evt => {
        FB.XFBML.parse();
    });
    //bodytextEditor.value.instance.saveDraft = saveNews;
    //bodytextEditor.value.instance.uploadImage = publishedStore.publishInlineImage;
    bodytextEditor.value.instance.uploadImage = publishInlineImage;
    bodytextEditor.value.instance.NewspaperId = route.params.newspaperid;
    //CKEditorInspector.attach( bodytextEditor.value.instance );
}

// Image management
const showDamBrowser = ref(false);
const damContext = ref("");

function onDamBrowse(show, context = "attach") {
    modalDamBrowser.show();
    showDamBrowser.value = true;
    damContext.value = context;
}

function onDamClosing() {
    showDamBrowser.value = false;
}

function onDamClose() {
    modalDamBrowser.hide();
}

function onAddImage(image) {
    bodytextEditor.value.instance.execute('insertImage', { source: image.publicUrl+ "/" + image.url + "?uid=" + image.remote_id });
    let lastRange = bodytextEditor.value.instance.ui.editor.model.document.selection.getLastRange();
    bodytextEditor.value.instance.model.change( writer => {
        writer.setSelection( writer.createPositionAfter(  bodytextEditor.value.instance.ui.editor.model.document.selection.getSelectedElement()) );
    } );
}

// Related
const showRelatedBrowser = ref(false);

function onRelatedBrowse() {
    modalRelatedBrowser.show();
    showRelatedBrowser.value = true;
}

function onRelatedClose() {
    modalRelatedBrowser.hide();
}

function onRelatedClosing() {
    showRelatedBrowser.value = false;
}

function onAddRelated(remote) {
    if(!remoteDetail.value.related.find((item) => {
        return item.uid == remote.uid;
    })) {
        remoteDetail.value.related.push({
            uid: remote.uid,
            datetime: remote.datetime,
            endtime: remote.endtime,
            hidden: remote.hidden,
            sorting: 9999,
            starttime: remote.starttime,
            title: remote.title,
        });
    }
}

const enabledDate = ref(true);

function onStartTimeChange(value) {
    if(value) {
        publishedStore.remoteDetail.datetime = value;
        enabledDate.value = false;
    } else {
        enabledDate.value = true;
        publishedStore.remoteDetail.datetime = null;
    }
}

// Publishing
const showPublish = ref(false);

function publishNews() {
    if (document.getElementsByName("remote-edit")[0].checkValidity()) {
        publishedStore.remoteDetail.bodytext = editorData;
        showPublish.value = true;
    } else {
        document.getElementsByName("remote-edit")[0].reportValidity();
    }
    //saveNewsAndClose();
}

function onPublishClose(value) {
    showPublish.value = false;
    if(value == 'done') {
        closeNews();
    }
}
</script>

<template>
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Modifica pubblicazione su <mark>{{ newsPaperStore.getNewspaperName(route.params.newspaperid) }}</mark></h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
          <li class="breadcrumb-item active">News</li>
        </ol>
      </div>
    </div>
  </div>
</section>

<section class="content">
    <div class="container-fluid">
        <div class="card card-default">
            <form name="remote-edit">
              <div class="card-body">
              
                <div class="form-group">
                    <label for="title">Titolo</label>
                    <input required type="text" class="form-control" id="title" v-model="remoteDetail.title" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="form-group">
                    <label for="short">Sottotitolo</label>
                    <!--<input type="text" class="form-control" id="short" v-model="remoteDetail.short" @keydown.enter="(e) => e.preventDefault()">-->
                    <textarea class="form-control" rows="2" id="short" v-model="remoteDetail.short" @keydown.enter="(e) => e.preventDefault()"></textarea>
                </div>
                <div class="form-group">
                    <label>Testo</label>
                    <div id="editor-container">
                        <ckeditor ref="bodytextEditor" :disabled=" (remoteDetail.type == 0) ? false : true " :editor="editor" v-model="editorData" :config="bodytextEditorConfig" @ready="onBodytextEditorReady()"></ckeditor>
                    </div>
                </div>
                <div class="form-group">
                    <label for="short">Parole chiave <small>(separate da ,)</small></label>
                    <input type="text" :disabled=" (remoteDetail.type == 0) ? false : true " class="form-control" id="keywords" v-model="remoteDetail.keywords" @keydown.enter="(e) => e.preventDefault()">
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="title">Data</label>
                            <datepicker required v-model="remoteDetail.datetime" :disabled="!enabledDate" id="datetime" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" />
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="author">Autore</label>
                            <input type="text" :disabled=" (remoteDetail.type == 0) ? false : true " class="form-control" v-model="remoteDetail.author" id="author" @keydown.enter="(e) => e.preventDefault()" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="title">Data inizio</label>
                            <!-- input type="text" class="form-control" id="starttime" v-model="draftDetail.starttime" -->
                            <datepicker v-model="remoteDetail.starttime" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" @update:model-value="onStartTimeChange" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="title">Data fine</label>
                            <!-- input type="text" class="form-control" id="endtime" v-model="draftDetail.endtime" -->
                            <datepicker v-model="remoteDetail.endtime" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <label>Posizione</label>
                        <div class="form-group">
                        <div v-for="(position, index) in newsPaperStore.positions" :key="position.id">
                            <div class="icheck-primary d-inline">
                                <input type="checkbox" :id="position.id" :true-value="[]" v-model="remoteDetail.positions" :value="position.id">
                                <label :for="position.id">{{position.name}}</label>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label></label>
                        <div class="form-group clearfix">
                            <div  class="icheck-primary d-inline">
                                <input type="checkbox" id="premium" true-value="1" false-value="0" v-model="remoteDetail.premium">
                                <label for="premium">PREMIUM News</label>
                            </div>
                        </div>

                        <div class="form-group clearfix">
                            <div  class="icheck-primary d-inline">
                                <input type="checkbox"  id="hidden" true-value="1" false-value="0" v-model="remoteDetail.hidden">
                                <label for="hidden">Nascondi</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data inizio boxatura</label>
                        <datepicker v-model="remoteDetail.tx_ttnewsextra_boxed_start" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" />
                    </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                        <label for="title">Data fine boxatura</label>
                        <datepicker v-model="remoteDetail.tx_ttnewsextra_boxed_end" locale="it" format="dd/MM/yyyy HH:mm" auto-apply :close-on-auto-apply="false" partial-flow :flow="['calendar', 'time']" :text-input="textInputOptions" />
                    </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <label>Immagini</label>
                        <dam-images :store="publishedStore" :media_file_path="'/published-images'" @ondambrowse="onDamBrowse(true, 'attach')" @ondamedit="(media) => openEditor(media, 'attachment')" />
                        
                    </div>
                    <div class="col-md-6">
                        <label>Allegati</label>
                        <dam-files :store="publishedStore" :media_file_path="'/published'" @ondambrowse="onDamBrowse(true, 'file')" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 ">
                        <label>Notizie correlate</label>
                        <RelatedRemoteNews  @onrelatedbrowse="onRelatedBrowse(true)" v-model="remoteDetail.related" />
                    </div>
                </div>
                
              </div>
                
              <div class="card-footer">
                <div class="float-right">
                  <div class="btn btn-sm btn-secondary" @click="closeNews()">Chiudi</div>
                  <div class="btn btn-sm btn-secondary ml-1" @click="saveNews()">Salva</div>
                  <div class="btn btn-sm btn-secondary ml-1" @click="saveNewsAndClose()">Salva e chiudi</div>
                  <div class="btn btn-sm btn-primary ml-1" @click="publishNews()">Pubblica</div>
                </div>
              </div>
                
            </form>
        </div>
    </div>
</section>
<div class="modal fade" ref="damEditor"  tabindex="-1" role="dialog" data-keyboard="false" >
    <div class="modal-dialog modal-fullscreen" role="document">
        <div class="modal-content">
            <!--div class="modal-header">
                <h4 class="modal-title">Modifica immagine</h4>
            </div -->
            <div class="modal-body" style="background-color: black;">
                <cropper v-if="imgToEdit" :src="imgToEdit" :default-size="defaultSize" ref="imageEditor" default-boundaries="fit" :auto-zoom="false" image-restriction="fit-area" />
                <div class="image-editor-controls">
                    <button type="button" @click="rotate(-90)"><i class="fas fa-rotate-left"></i></button>
                    <button type="button" @click="rotate(90)"><i class="fas fa-rotate-right"></i></button>
                    <button type="button" @click="flip(true, false)"><i class="fas fa-arrows-left-right"></i></button>
                    <button type="button" @click="flip(false, true)"><i class="fas fa-arrows-up-down"></i></button>
                    <button type="button" @click="saveImage()"><i class="fas fa-save"></i></button>
                    <button type="button" @click="closeImageEditor()"><i class="fas fa-close"></i></button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" ref="damBrowser"  tabindex="-1" role="dialog" data-keyboard="false" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Media</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
    
            <div class="modal-body">
                <DamImageBrowser ref="modal_browser" :show="showDamBrowser" :store="publishedStore" @addimage="onAddImage" @hidebsmodal="onDamClose" :context="damContext" />
            </div>
        </div>
    </div>
</div>
<div class="modal fade" ref="relatedBrowser" tabindex="-1" role="dialog" data-keyboard="false">
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Notizie correlate</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>

            <div class="modal-body">
                <RelatedRemoteBrowser ref="modal_related_browser" :show="showRelatedBrowser" :language="remoteDetail.language_id" :remote_id="remoteDetail.uid" @hidebsmodal="onRelatedClose" @addrelated="onAddRelated" />
            </div>
        </div>
    </div>
</div>

<!-- BEGIN: Modal Publish -->
<PublishPublished id="modal_publish" ref="modal_publish" :show="showPublish" v-if="newsPaperStore.selectedNewspaper" @modalAction="(value) => onPublishClose(value)" />
<!-- BEGIN: Modal Publish -->

</template>
