<script lang="ts">
  import { nextTick, ref } from 'vue';
  export default {
    setup() {
        const shouldRender = ref(false);
        setTimeout(() => {
            nextTick(() => {
            shouldRender.value = true;
        });
        }, 100);
      return { shouldRender }; 
    }
  }
</script>
<template>
  <div>
    <slot v-if="shouldRender" />
  </div>
</template>