<script setup>
import { ref, onMounted, computed } from 'vue';
import { Nav } from '@/components';
import { useAuthStore } from '@/stores';
import { useNotification } from "@kyvg/vue3-notification";

const { notify } = useNotification();
const authStore = useAuthStore();

const recaptchaScript = document.createElement("script");
recaptchaScript.setAttribute(
    "src",
    "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2"
);
document.head.appendChild(recaptchaScript);


const notification_ref = ref(null);
let notificationModal = null;

function onNotificationModalClose() {
    authStore.user.notifications = null;
    authStore.persist();
}

const showNotifications = computed( () => {
    const res = authStore.user && authStore.user.notifications?.data.length > 0;
    if(res) {
        notificationModal.show();
    }
    return res;
});

onMounted(() => {
    notificationModal = new bootstrap.Modal(notification_ref.value);
    notificationModal._config.keyboard = false;
    notificationModal._config.backdrop = "static";
    $(notificationModal._element).on("hide.bs.modal", onNotificationModalClose);

    if(!authStore.isSupportedBrowser()) {
        notify({
            title: "ATTENZIONE!",
            text: "Stai utilizzando PUBLISHER su un browser non ancora ottimizzato.",
            type: 'warning',
            duration: -1
        });
    }
});
</script>

<template>
     <div id="fb-root"></div>

    <notifications position="top right">
        <template #body="props">
        <div :class="`toast bg-${props.item.type} fade show`" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <strong class="mr-auto">{{ props.item.title }}</strong>
                <!-- small>Subtitle</small -->
                <button data-dismiss="toast" type="button" class="ml-2 mb-1 close" aria-label="Close" @click="props.close">
			        <span aria-hidden="true">×</span>
		        </button>
            </div>
            <div class="toast-body" v-html="props.item.text" />
        </div>
        </template>
    </notifications>

    <Nav v-if="authStore.user" />
    
    <div class="wrapper">
        <router-view />
    </div>

    <DialogsWrapper />
    <div v-show="showNotifications" class="modal fade" ref="notification_ref"  tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" >
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Notifiche</h4>
            </div>
            <div class="modal-body">
                <div v-if="authStore.user" class="notification-item" v-for="(notification, index) in authStore.user.notifications?.data">
                <h3>{{ notification.title }}</h3>
                <template v-if="notification.summary">
                    <div class="summary" v-html="notification.summary"></div>
                    <!-- router-link to="/user/notifications" data-dismiss="modal">Mostra dettaglio</router-link -->
                </template>
                <div v-else class="description" v-html="notification.description"></div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" aria-label="Close">OK</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

</script>
<style>
@import '@/assets/base.css';

.vue-notification {
    font-size: 1rem;
}
</style>