<script setup>
import version from '@/version';
const installation = import.meta.env.VITE_APP_INSTALLATION;
</script>

<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-light-primary elevation-2">
    <!-- Brand Logo -->
    <router-link to="/" class="brand-link">
      <img src="@/assets/img/logo.png" alt="PUBLISHER" class="brand-image">
      <span class="text-primary brand-text font-weight">PUBLISHER</span>
      
      <template v-if="installation == 'DEV'">
        <sup class="bg-danger rounded" style="padding:1px 5px 2px; margin-left:5px;"><small>DEV</small></sup>
      </template>
      <template v-else-if="installation == 'PREVIEW'">
        <sup class="bg-warning rounded" style="padding:1px 5px 2px; margin-left:5px;"><small>BETA</small></sup>
      </template>
      
    </router-link>
    <!-- Sidebar -->
    <div class="sidebar">
      <AdminNav />
    </div>
  </aside> 
  <div class="content-wrapper">
    <!-- List / -->
    <router-view></router-view>
  </div>
  
  <footer class="main-footer">
    Copyright © <strong>More News.</strong> All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
    <b>Version</b> {{ version }}
    </div>
  </footer>
    
    
</template>
<script>
import { defineComponent } from 'vue';
import AdminNav from '@/components/AdminNav.vue';

export default defineComponent({
    name: "AdminLayout",
    components: {
      AdminNav
    }
});
</script>
