import { defineStore } from 'pinia';
import i18n from '@/i18n';
import { router } from '@/router';
import { useNotification } from "@kyvg/vue3-notification";
import { useBackendStore } from './backend.store';
import * as Sentry from "@sentry/vue";
import { fetchWrapper } from '../_helpers/fetch-wrapper';
import UAParser from 'ua-parser-js';

const baseUrl = `${import.meta.env.VITE_API_URL}`;


const defaultPreferences = {
    hideArchiveTree: false,
    newspapers: [],
    startingTab: 'draft-news',
    showURLAfterPublishing: "1",
    autoSaveDraft: "0",
    lastPreferences: {
        news: {
            newsPageSize: 50,
            relatedPageSize: 50,
            quickFilter: "all"
        },
        images: {
            imagePageSize: 50,
            selectedArchive: ""
        }
    }
};

function initUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user) {
        Sentry.setUser({ email: user.user });
        const preferences = Object.fromEntries(Object.entries(defaultPreferences));
        user.preferences = Object.assign(preferences, user.preferences);
    }
    return user;
}

const browserDetect = () => {
    let ua = window.navigator.userAgent;
    let parser = new UAParser(ua);
    return parser.getResult();
}

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: initUser(),
        returnUrl: null,
        i18n: i18n,
        browser: browserDetect()
    }),
    actions: {
        isSupportedBrowser() {
            return this.browser.device.type == undefined;
        },
        async updatePreferences(profile) {
            return new Promise( (resolve, reject) => {
                delete profile.preferences.tab;
                fetchWrapper.post(`${baseUrl}/user/profile`, { profile }).then( (value) => {
                    this.user.preferences = profile.preferences;
                    this.persist();
                    resolve(value);
                }).catch( (reason) => {
                    console.error(reason);
                    reject(reason);
                });
            } );
        },
        async login(email, password) {
            try {
                const user = await fetchWrapper.post(`${baseUrl}/login`, { email, password });

                var base64Url = user.access_token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
            
                var token_data = JSON.parse(jsonPayload);
                user.exp = token_data.exp * 1000;   // expiration
                user.iat = token_data.iat * 1000;   // issued at
                const preferences = Object.fromEntries(Object.entries(defaultPreferences));
                user.preferences = Object.assign(preferences, user.preferences);
                this.user = user;

                // store user details and jwt in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(this.user));
                // Set user in Sentry
                Sentry.setUser({ email: email });
                // Get backends info
                let backendStore = useBackendStore();
                backendStore.getBackend();
                router.push(this.returnUrl || '/');
            } catch (error) {
                const { notify } = useNotification();
                notify({
                    title: "Errore",
                    text: this.i18n.global.t('wrong-credentials'),
                    type: "danger"
                });
            }
        },
        logout() {
            this.user = null;
            localStorage.removeItem('user');
            Sentry.setUser(null);
            // Remove Modal backdrop if any
            document.querySelectorAll(".modal-backdrop").forEach(el => { el.remove(); });
            router.push('/account/login');
        },
        persist() {
            localStorage.setItem('user', JSON.stringify(this.user));
        },
        async me() {
            const user = await fetchWrapper.get(`${baseUrl}/me`);
            const preferences = Object.fromEntries(Object.entries(defaultPreferences));
            user.user.preferences = Object.assign(preferences, user.user.preferences);
            return user;
        },
        token_valid() {
            return new Promise((resolve, reject) => {
                if (Date.now() >= this.user.exp) {
                    // Refresh Token
                    const requestOptions = {
                        method: 'POST',
                        headers: { Authorization: `Bearer ${this.user.access_token}` }
                    };
                    fetch(baseUrl + '/refresh', requestOptions).then(response => {
                        if(response.ok) {
                            response.json().then(data => {
                                console.log("Refresh response:" , data);
                                this.user.access_token = data.token;
    
                                var base64Url = data.token.split('.')[1];
                                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                                var jsonPayload = decodeURIComponent(atob(base64).split("").map(function(c) {
                                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                                }).join(''));
                                var token_data = JSON.parse(jsonPayload);
                                this.user.exp = token_data.exp * 1000;  // expiration
                                this.user.iat = token_data.iat * 1000;  // issued at
                                // store user details and jwt in local storage to keep user logged in between page refreshes
                                localStorage.setItem('user', JSON.stringify(this.user));
    
                                resolve({ Authorization: `Bearer ${this.user.access_token}` });
                            }).catch(reason => {
                                console.warn("Cannot parse refresh token JSON response");
                                this.logout();
                                reject(reason);
                            });
                        } else {
                            console.warn("Cannot refresh token");
                            this.logout();
                            reject(response.status);
                        }

                    }).catch(reason => {
                        console.warn("Exception refreshing token");
                        this.logout();
                        reject(reason);
                    });
                } else {
                    resolve();
                }
            });
        }
    }
});