import Layout from '@/views/layouts/NewspaperLayout.vue';
import { DraftList, DraftEdit, NewsView, RemoteEdit } from '@/views/draft';
import { useDraftStore, useNewsPaperStore } from '@/stores';

function redirect(to) {
    const store = useDraftStore();
    const newsPaperStore = useNewsPaperStore();
    if(!('newspaperid' in to.params) && newsPaperStore.selectedNewspaper !== "") {
        return { name: 'draft-list-newspaper', path: to.path, query: to.query, params:  { newspaperid: newsPaperStore.selectedNewspaper }};
    }
    //return to;
}

export default {
    path: '/draft',
    name: 'draft',
    component: Layout,
    children: [
        {
            path: '',
            name: "draft-list",
            component: DraftList,
            meta: { watchParam: 'newspaperid'},
            beforeEnter: [redirect]
        },
        { path: 'newspaper/:newspaperid', name: 'draft-list-newspaper', component: DraftList, meta: { watchParam: 'newspaperid'} },
        { path: 'newspaper/:newspaperid/new', name: "draft-new", component: DraftEdit },
        { path: 'newspaper/:newspaperid/:uuid', name: "draft-edit", component: DraftEdit },
        { path: 'newspaper/:newspaperid/:uuid/:langid/new', name: "draft-transl-new", component: DraftEdit },
        { path: 'newspaper/:newspaperid/view/:uuid', name: "news-view", component: NewsView },
        { path: 'newspaper/:newspaperid/remote/:id', name: "remote-edit", component: RemoteEdit },
    ]
};
