import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { List } from '@ckeditor/ckeditor5-list';
import '@/ckeditor5/translations/it';

const editorConfig = ({
    plugins: [
      Essentials,
      Bold,
      Italic,
      Underline,
      Paragraph,
      Heading,
      List
    ],
    toolbar: [
        'heading',
        'bold', 
        'italic',
        'underline',
        '|',
        'undo',
        'redo',
        '|',
        'bulletedList', 'numberedList'
    ],
});

export default editorConfig;