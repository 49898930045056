<script setup>
import { ref } from 'vue';
import { fetchWrapper } from '@/_helpers/fetch-wrapper';
import { useLoading } from '@/loader';
import { format, formatDistance, formatRelative, subDays, parseISO } from 'date-fns'
import { it } from 'date-fns/locale';

const baseUrl = `${import.meta.env.VITE_API_URL}/user/notifications`;
const loader = useLoading();

const notifications = ref({
    totalRows: 0,
    rows: [],
});

let lastDate = null;
let currDate = null;

function listNotifications() {
    loader.show();
    fetchWrapper.get(baseUrl).then( (result) => {
        notifications.value.rows = result.data.data;
        notifications.value.totalRows = result.data.total;
        console.log(notifications.value);
    }).finally( () => {
        loader.hide();
    });
}

function init() {
    listNotifications();
}

function displayTimeLabel(notification) {
    currDate = format( parseISO(notification.from), 'dd/MM/yyyy');
    let res = currDate != lastDate;
    lastDate = currDate;
    return res;
}

init();

</script>

<template>
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1>Elenco notifiche</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                        <li class="breadcrumb-item active">Elenco notifiche</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="timeline">
                        <template v-for="(notification, index) in notifications.rows">
                            <div class="time-label" v-if="displayTimeLabel(notification)">
                                <span class="bg-red">{{ format( parseISO(notification.from), 'dd/MM/yyyy') }}</span>
                            </div>
                            <div>
                                <div class="timeline-item">
                                    <span class="time"><i class="fas fa-clock" style="cursor: default !important"></i> {{ formatDistance(parseISO(notification.from), new Date(), { addSuffix: true, locale: it }) }}</span>
                                    <h3 class="timeline-header"><strong>{{ notification.title }}</strong> </h3>
                                    <div class="timeline-body" v-html="notification.summary"></div>
                                    <hr>
                                    <div class="timeline-body" v-html="notification.description"></div>
                                </div>
                            </div>
                        </template>
                        <div>
                            <i class="fas fa-clock bg-gray" style="cursor: default !important"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>