import Layout from '@/views/layouts/AdminLayout.vue';
import { UserEdit, UserList, UserNotificationList, UserNotificationEdit } from '@/views/admin';
import { useAuthStore } from '../stores/auth.store';

function checkUser(to) {
    const store = useAuthStore();
    if(store.user && store.user.roles.includes('User Manager')) {
        return;
    }
    return { name: 'draft-list' };

    //return to;
}

export default {
    path: '/admin',
    component: Layout,
    beforeEnter: [checkUser],
    children: [
        { path: 'users', component: UserList, name: 'users-admin' },
        { path: 'users/create', component: UserEdit },
        { path: 'users/:id', component: UserEdit },
        { path: 'notifications', component: UserNotificationList, name: 'notifications-admin-list' },
        { path: 'notifications/create', component: UserNotificationEdit },
        { path: 'notifications/:id', component: UserNotificationEdit },
    ]
};