<script setup>
import { computed, inject } from 'vue';

const props = defineProps({
    category: {
        type: Object,
        required: true
    },
    level: {
        type: Number,
        required: true,
        default: 0
    }
})

const prefix = computed(() => {
    return "-".repeat(props.level)
})

const selectedCategory = inject('selectedCategory');

const isSelected = computed(() => {
    return selectedCategory.value === props.category.uid
})

</script>

<template>
    <option :value="category.uid" :selected="isSelected">
        {{ prefix }} {{ category.name }}
    </option>
    <CategoryOption v-for="child in category.child" :category="child" :level="level + 1" />
</template>