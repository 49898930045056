<script setup>
import { ref } from 'vue';
import CategoryTree from './CategoryTree.vue';
import { useNewsPaperStore } from '@/stores';
import { useDraftStore } from '@/stores';
import { useNewsStore } from '@/stores';
import { usePublishedStore } from '../../stores';
const newspaperStore = useNewsPaperStore();
const draftStore = useDraftStore();
const newsStore = useNewsStore();

</script>

<template>
      <li :class="(isSelected ? 'category-item selected' : 'category-item')">
          <i v-if="category.child" :class="(isOpened ? 'far fa-square-minus' : 'fas fa-square-plus')" @click="toggleStatus" class="mr-1"></i>
          <span class="category" @click="catSelect(category.uid)"><i :class="(isSelected ? 'fas fa-square-check mr-1' : 'far fa-square mr-1')"></i>{{ category.name }} </span>
          <CategoryTree :store="store" v-if="category.child" :openedTree=openedTree :republish="republish" :publish="publish" :categories="category.child" :level=level+1  :newspaper_id="newspaper_id" v-bind:opened=isOpened @catselect="childCatSelect" @togglecat="toggleCat" />
      </li>
</template>

<script>
export default {
    data() {
        return {
            selected: false,
        };
    },
    props: {
        category: Object,
        level: Number,
        newspaper_id: String,
        publish: ref(false),
        republish: ref(false),
        openedTree: Array,
        store: null,
        opened: false,
    },
    computed: {
        isSelected() {
            let res;
            const draftStore = useDraftStore();
            const publishedStore = usePublishedStore();
            //debugger;
            if(this.republish) {
                res = publishedStore.remoteDetail?.categories?.indexOf(this.category.uid) > -1;
            } else if(this.publish) {
                res = draftStore.publishing[this.newspaper_id]?.news.category.indexOf(this.category.uid) > -1;
                //res = this.draftStore.publishingCats.indexOf(this.newspaper_id + "-" + this.category.uid) > -1;
            } else {
                res = this.store.lastCat == this.newspaper_id + "-" + this.category.uid;
            }
            return res;
        },
        isOpened() {
            //return this.draftStore.categoryTree.opened.indexOf(this.newspaper_id + "-" + this.category.uid) > -1;
            return this.openedTree.indexOf(this.newspaper_id + "-" + this.category.uid) > -1;
        }
    },
    mounted() {
        if(this.opened && this.openedTree.indexOf(this.newspaper_id + "-" + this.category.uid) == -1) {
            this.openedTree.push(this.newspaper_id + "-" + this.category.uid);
        }
    },
    methods: {
        toggleCat(value) {
            this.$emit('togglecat', value);
        },
        toggleStatus(event) {
            //this.subOpened = !this.subOpened;
            //this.category.opened = !this.category.opened;
            let fullCat = this.newspaper_id + "-" + this.category.uid;
            //let catIndex = this.draftStore.categoryTree.opened.indexOf(fullCat);
            let catIndex = this.openedTree.indexOf(fullCat);
            if(catIndex > -1) {
                //this.draftStore.categoryTree.opened.splice(catIndex, 1);
                this.openedTree.splice(catIndex, 1);
            } else {
                //this.draftStore.categoryTree.opened.push(fullCat);
                this.openedTree.push(fullCat);
            }
            //this.draftStore.persistState();
            this.$emit('togglecat', fullCat);
        },
        catSelect(cat_id) {
            let newCat = this.newspaper_id + "-" + cat_id;
            this.$emit("catselect", newCat);
        },
        childCatSelect(cat_id) {
            this.$emit("catselect", cat_id);
        }
    }
}
</script>
