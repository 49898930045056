import { defineStore } from 'pinia';
import { fetchWrapper } from '../_helpers/fetch-wrapper';



const baseUrl = `${import.meta.env.VITE_API_URL}/journal`;

export const useNewsPaperStore = defineStore({
    id: 'newspaper',
    state: () => ({
        // initialize state from local storage
        newspaper: JSON.parse(localStorage.getItem('newspaper')),
        returnUrl: null,
        positions: [
            {
                id: 5,
                name: 'Prima Pagina 1'
            }, {
                id: 4,
                name: 'Prima Pagina 2'
            }, {
                id: 6,
                name: 'Prima Pagina 3'
            }, {
                id: 2,
                name: 'In Evidenza'
            }, {
                id: 10,
                name: 'Boxata Desktop'
            }, {
                id: 11,
                name: 'Boxata Mobile'
            }
        ],
        selectedNewspaper: "",
    }),
    actions: {
        async get() {
            const newspaper = await fetchWrapper.get(`${baseUrl}/newspaper`); 
            var giornali = new Object();
            for (const [rel_id, backend] of Object.entries(newspaper.data)) {
                if('newspapers' in backend && backend['newspapers']) {
                    for(const [newspaper_id, newspaper] of Object.entries(backend['newspapers'])) {
                        // Root
                        if(newspaper_id) {
                            this.mapCategories(newspaper.categories)
                            newspaper.backendId = backend.backend_id;
                            if(!(backend.backend_id in giornali)) {
                                giornali[backend.backend_id] = new Object();
                            }
                            giornali[backend.backend_id][newspaper.name] = newspaper;
                        }
                    }
                    let sorted = Object.keys(giornali[backend.backend_id])
                        .sort()
                        .reduce(function (acc, key) {
                            acc[key] = giornali[backend.backend_id][key];
                            return acc;
                        }, {});
                    giornali[backend.backend_id] = sorted;
                }
            }
            this.newspaper = giornali;
            localStorage.setItem('newspaper', JSON.stringify(giornali));
        },
        async duplicateNews(newspaper_id, remote_id) {
            return new Promise( (resolve, reject) => {
                fetchWrapper.get(`${import.meta.env.VITE_API_URL}/journal/remote/${newspaper_id}/${remote_id}/clone`).then((res) => {
                    resolve(res);
                }).catch((reason) => {
                    reject("Impossibile duplicare la notizia. Codice errore: " + reason);
                });
            });
        },
        async deletePublished(newspaper_id, remote_id) {
            return new Promise((resolve, reject) => {
                fetchWrapper.delete(`${import.meta.env.VITE_API_URL}/journal/remote/${newspaper_id}/${remote_id}`).then(() => {
                    resolve();
                }).catch((reason) => {
                    reject("Impossibile eliminare la notizia dal server. Codice errore: " + reason);
                });
            });
        },
        async getNewspapersForUser(user_id) {
            /*
            const usersStore = useUsersStore();
            const { user } = storeToRefs(usersStore);
            */
            const newspaper = await fetchWrapper.get(`${import.meta.env.VITE_API_URL}/admin/users/${user_id}/newspapers`);
            var giornali = new Object();
            for (const [rel_id, backend] of Object.entries(newspaper.data)) {
                if('newspapers' in backend && backend['newspapers']) {
                    for(const [newspaper_id, newspaper] of Object.entries(backend['newspapers'])) {
                        // Root
                        if(newspaper_id) {
                            newspaper.backendId = backend.backend_id;
                            if(!(backend.backend_id in giornali)) {
                                giornali[backend.backend_id] = new Object();
                            }
                            giornali[backend.backend_id][newspaper.name] = newspaper;
                        }
                    }
                    let sorted = Object.keys(giornali[backend.backend_id])
                        .sort()
                        .reduce(function (acc, key) {
                            acc[key] = giornali[backend.backend_id][key];
                            return acc;
                        }, {});
                    giornali[backend.backend_id] = sorted;
                }
            }
            console.log("giornali", giornali);
            return giornali;
        },
        getNewspaperLangs(newspaper_id) {
            if(newspaper_id) {
                const newspaper = this.getNewspaperById(newspaper_id);
                if(newspaper)
                    return newspaper.lang;
            }
            return [];
        },
        getNewspaperById(newspaper_id) {
            let parts = newspaper_id.split('-');
            if(parts[0] in this.newspaper) {
                for(const [name, newspaper] of Object.entries(this.newspaper[parts[0]])) {
                    if(newspaper.uid == parts[1]) {
                        return newspaper;
                    }
                }
                console.log("Newspaper not found", newspaper_id);
            } else {
                console.log("No access to published newspaper", newspaper_id);
            }

        },
        getNewspaperName(newspaper_id) {
            let parts = newspaper_id.split('-');
            if(parts[0] in this.newspaper) {
                for(const [name, newspaper] of Object.entries(this.newspaper[parts[0]])) {
                    if(newspaper.uid == parts[1]) return name;
                }
            } else {
                console.log("No access to published newspaper");
            }
        },
        resetLocalState() {
            this.newspaper = {};
            localStorage.removeItem('newspaper');
        },
        persistState() {
            localStorage.setItem('newspaper', JSON.stringify(this.newspaper));
        },
        getCategoryName(server_id, newspaper_id, category_id) {
            const newspaper = this.getNewspaperById(server_id + "-" + newspaper_id);
            if(newspaper) {
                const cat = this.searchCategoryById(newspaper.categories, category_id);
                if(cat) { 
                    return cat.name
                }
            }
        },
        searchCategoryById(categories, cat_id) {
            for(let key in categories) {
                let element = categories[key];
                if(element.uid == cat_id) {
                    return element;
                }
                if(element.child) {
                    let found = this.searchCategoryById(element.child, cat_id);
                    if(found) {
                        return found;
                    }
                }
            }
        },
        mapCategories(categories) {
            categories.forEach(element => {
                //element.opened = element.uid ;
                if(element.child) {
                    this.mapCategories(element.child);
                }
            });
        },
        getAuthors(newspaper_id) {
            const newspaper = this.getNewspaperById(newspaper_id);
            if(newspaper) {
                return newspaper['authors'];
            }
            return [];
        },
        getAuthorById(newspaper_id, author_id) {
            const authors = this.getAuthors(newspaper_id);
            return authors.find( (item) => {
                return item.id == author_id;
            });
        },
        getPublishedUrls(newspaper_id, remote_id) {
            return new Promise( (resolve, reject) => {
                fetchWrapper.get(`${import.meta.env.VITE_API_URL}/journal/remote/${newspaper_id}/${remote_id}/urls`)
                    .then( (value) => {
                        resolve(value);
                    })
                    .catch( (reason) => {
                        reject(reason);
                    });
            });
        }
    }
});
