<script setup>
import { useNewsStore } from '@/stores';
import { storeToRefs } from "pinia";
import DamImage from '../../components/newspaper/DamImage.vue';

const newsStore = useNewsStore();
const { isNewsLoading, newsList, newsDetail } = storeToRefs(newsStore);


</script>

<template>
    <div class="col" >
        <h3>News list</h3>
        <div :class="isNewsLoading">
          <div class="news-item" v-if="newsList" v-for="news in newsList" :key="news.uid" :id="news.uid" @click="showNewsDetail(news.uid)">
            {{ news.title }}
          </div>
        </div>
    </div>

    <div class="modal fade" ref="modal_wait" id="modal_wait" tabindex="-1" role="dialog" data-backdrop="static">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
        
          
      
          <div class="modal-body">
            <div class="container h-100">
              <div class="row align-items-center h-100">
                <div class="col">
                  <form name="news-edit" @submit.prevent>
                    <div class="form-group">
                      <label for="title">Title</label>
                      <input type="text" class="form-control" id="title" v-model="newsDetail.title">
                    </div>
                    <div class="form-group">
                      <label for="bodytext">Text</label>
                      <textarea class="form-control" rows="30" v-model="newsDetail.bodytext"></textarea>
                      <!-- ckeditor :editor="editor" v-model="newsDetail.bodytext" :config="editorConfig"></ckeditor -->
                    </div>
                    <div class="form-group row">
                      <div v-for="dam_image in newsDetail.dam_images" class="dam-image">
                        {{ dam_image }}
                        <DamImage :news_id="news_id" :dam_uid="dam_image" />
                      </div>
                    </div>
                    <div class="form-group">
                      <button class="btn btn-primary" @click="saveNews()">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
/*
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import SourceEditing from '@ckeditor/ckeditor5-source-editing/src/sourceediting';
*/
export default {
  mounted() {
   this.modal = new bootstrap.Modal(this.$refs.modal_wait);
  },
  methods: {
    showNewsDetail(news_id) {
      this.news_id = news_id;
      this.newsStore.fetchNewsDetail(news_id);
      this.modal.show();
    },
    async saveNews() {
      await this.newsStore.saveNews();
      this.modal.hide();
      this.newsStore.newsDetail = Object;
    }
  },
  data() {
    return {
      news_id: Number,
      tableCols: [
        { label: "Title", width: "60%", field: "title" },
        { label: "Date time", field: "datetime" }
      ]
    }
  }
  /*
  data() {
    return {
        editor: ClassicEditor,
        editorConfig: {
            // The configuration of the editor.
            plugins: [
              EssentialsPlugin,
              BoldPlugin
            ],
            toolbar: {
              items: [
                'bold'
              ]
            }
        }
    };  
  }
  */
}
</script>
