<script setup>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import { useAuthStore } from '@/stores';
import { useNewsPaperStore } from '@/stores';
import { useLoading } from '@/loader';
import version from '@/version';

const loader = useLoading();
const authStore = useAuthStore();

const installation = import.meta.env.VITE_APP_INSTALLATION;

const schema = Yup.object().shape({
    username: Yup.string().required('Username obbligatoria'),
    password: Yup.string().required('Password obbligatoria')
});


async function onSubmit(values) {
    loader.show();
    const newsPaperStore = useNewsPaperStore();
    newsPaperStore.resetLocalState();
    const { username, password } = values;
    const notifications = await authStore.login(username, password);
    loader.hide();
}

</script>

<template>
    <div class="card card-outline card-primary">
        <div class="card-header text-center">
            <img src="@/assets/img/logo.png" alt="PUBLISHER" class="brand-image" style="max-height:33px; margin-right:0.5rem;">
			<span class="text-primary brand-text font-weight" style="font-size:1.25rem;">PUBLISHER</span>
            <template v-if="installation == 'DEV'">
                <sup class="bg-danger rounded" style="padding:1px 5px 2px; margin-left:5px;"><small>DEV</small></sup>
            </template>
            <template v-else-if="installation == 'PREVIEW'">
                <sup class="bg-warning rounded" style="padding:1px 5px 2px; margin-left:5px;"><small>BETA</small></sup>
            </template>
        </div>
        <div class="card-body">
		    <p class="login-box-msg">Accedi per iniziare la sessione</p>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
			    <div class="input-group mb-3">
                    <Field name="username" type="text" class="form-control" :class="{ 'is-invalid': errors.username }" placeholder="Username" />
					<div class="input-group-append">
						<div class="input-group-text">
							<span class="fas fa-user"></span>
						</div>
					</div>
                    <div class="invalid-feedback">{{ errors.username }}</div>
                </div>
                <div class="input-group mb-3">
                    <Field name="password" type="password" class="form-control" :class="{ 'is-invalid': errors.password }" placeholder="Password" />
					<div class="input-group-append">
						<div class="input-group-text">
							<span class="fas fa-lock"></span>
						</div>
					</div>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                </div>
				<div class="row">
                    <!--
					<div class="col-8">
						<div class="icheck-primary">
							<input type="checkbox" id="remember">
							<label for="remember">Ricordami</label>
						</div>
					</div>
                    -->
					<div class="col-12">
						<button type="submit" class="btn btn-primary btn-block">Login</button>
					</div>
				</div>
            </Form>
        </div>
        <div class="card-footer">
            <small class="float-right">v. {{ version }}</small>
        </div>
    </div>
</template>