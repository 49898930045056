<script setup>
import { ref, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia';
import { useAuthStore, useNewsPaperStore } from '@/stores';
import { router } from '@/router';

const authStore = useAuthStore();
const newspaperStore = useNewsPaperStore();

const { user } = storeToRefs(authStore);


onMounted(() => {
  if(newspaperStore.selectedNewspaper) {
    router.push({path: '/draft/newspaper/' + newspaperStore.selectedNewspaper });
    return;
  }
})
</script>

<template>
<section class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1>Dashboard</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </div>
    </div>
  </div>
</section>


<section class="content">
    <div class="container-fluid">

        <div v-if="user" class="row">
            <section class="col-lg-7">
              <div class="card bg-gradient-primary">
                <div class="card-header">Ciao {{user.first_name}} {{user.last_name}}</div>
                <div class="card-body">
                    <h3>Per inserire una News seleziona dal menu di sinistra il giornale principale su cui verrà pubblicata</h3>
                </div>
              </div>
            </section>
        </div>

    </div>
</section>
</template>