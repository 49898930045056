<script setup>
import FolderTree from "./FolderTree.vue"
import { onMounted, onBeforeMount, getCurrentInstance, ref, computed } from "vue";
import { useAuthStore } from '../../stores';
import Lazy from "../Lazy.vue";

const authStore = useAuthStore();

const isOpening = ref(false);
const childMounting = ref(0);

const props = defineProps({
    folder: Object,
    level: Number,
    filemount_id: String,
    opened: Boolean,
    parentPath: String,
    baseUrl: String,
    checkSelected: {
        type: Function,
        default: null,
    }
});

const emit = defineEmits([
    "opening",
    "beforemount",
    "mounted",
    "folderselect",
    "folderIsSelected"
]);

const filemountLastPath = computed({
    get() {
        return authStore.user.preferences.lastPreferences.images.selectedArchive;
    },
    set(value) {
        if(authStore.user.preferences.lastPreferences.images.selectedArchive != value) {
            authStore.user.preferences.lastPreferences.images.selectedArchive = value;
            authStore.updatePreferences(authStore.user);
        }
    }
});

const isSelected = computed({
    get() {
        let result;
        if (props.checkSelected) {
            result = props.checkSelected(path.value);
            return 
        } else {
            result = filemountLastPath.value == path.value;
        }
        if(result) {
            emit("folderIsSelected", path.value);
        }
        return result;
    }
});

const path = computed({
    get() {
        return props.parentPath + "/" + props.folder.name;
    }
});

const childBeforeMount = async () => {
    childMounting.value++;
};

const childMounted = async () => {
    childMounting.value--;
    if (childMounting.value == 0) {
        isOpening.value = false;
        emit("opening", isOpening.value);
    }
};

const childOpening = (status) => {
    emit("opening", status);
};

const toggleStatus = async (event) => {
    if (!props.folder.opened) {
        isOpening.value = true;
        emit("opening", isOpening.value);
    }
    props.folder.opened = !props.folder.opened;
};


const folderSelect = () => {
    if(filemountLastPath.value != path.value) {
        //filemountLastPath.value = path.value;
        emit("folderselect", path.value);
    }
};
        
const childFolderSelect = (path) => {
    emit("folderselect", path);
};

const onFolderIsSelected = (path) => {
    emit("folderIsSelected", path);
}

onBeforeMount(() => {
    const { emit } = getCurrentInstance();
    emit('beforemount');
});

onMounted(() => {
    const { emit } = getCurrentInstance();
    emit('mounted');
});




</script>

<template>
    <li :class="(isSelected ? 'folder-item selected' : 'folder-item')">
        <i v-if="folder.child?.length" :class="(isOpening ? 'fas fa-spinner fa-spin' : (folder.opened ? 'far fa-folder-open mr-1' : 'far fa-folder-closed mr-1'))" @click="toggleStatus"></i>
		<i v-else class="far fa-folder mr-1"></i>
        <span class="folder" @click="folderSelect(folder.path)">{{ folder.name }}</span>
        <template v-if="(folder.opened)">
            <Lazy><FolderTree @beforemount="childBeforeMount" @folderIsSelected="onFolderIsSelected" @mounted="childMounted" :checkSelected="checkSelected"  :baseUrl="baseUrl" :parentPath="path" :folders="folder.child" :level=level+1  :filemount_id="filemount_id" v-bind:opened=folder.opened @folderselect="childFolderSelect" @opening="(status) => childOpening(status)" /></Lazy>
        </template>
    </li>
</template>
