<script setup>
import { router } from '@/router/index';
import version from '@/version';

const installation = import.meta.env.VITE_APP_INSTALLATION;

function getMenuItemOpenClass(item) {
    const className = router.currentRoute.value.path.startsWith(item) ? ' active' : '';
    return className;
}

</script>

<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-light-primary elevation-2">
        <!-- Brand Logo -->
        <router-link to="/" class="brand-link">
            <img src="@/assets/img/logo.png" alt="PUBLISHER" class="brand-image">
            <span class="text-primary brand-text font-weight">PUBLISHER</span>

            <template v-if="installation == 'DEV'">
                <sup class="bg-danger rounded" style="padding:1px 5px 2px; margin-left:5px;"><small>DEV</small></sup>
            </template>
            <template v-else-if="installation == 'PREVIEW'">
                <sup class="bg-warning rounded" style="padding:1px 5px 2px; margin-left:5px;"><small>BETA</small></sup>
            </template>

        </router-link>
        <!-- Sidebar -->
        <div class="sidebar">
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column nav-compact " data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item menu-open">
                        <router-link to="/user/profile" class="nav-link" :class="getMenuItemOpenClass('/user/profile')">
                            <i class="nav-icon fas fa-user-cog"></i>
                            <p>Profilo</p>
                        </router-link>
                        <router-link to="/user/notifications" class="nav-link" :class="getMenuItemOpenClass('/user/notifications')">
                            <i class="nav-icon fas fa-comments"></i>
                            <p>Notifiche</p>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>
    <div class="content-wrapper">
        <!-- Main content / -->
        <router-view></router-view>
    </div>
    
    <footer class="main-footer">
    Copyright © <strong>More News.</strong> All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
    <b>Version</b> {{ version }}
    </div>
  </footer>
    
</template>
